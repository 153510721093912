const pageTitles = [
    {
        "wiki": "Lung_cancer",
        "county": "Lung and Bronchus",
        "oncologist": "lung-cancer"
    },
    {
        "wiki": "Prostate_cancer",
        "county": "Prostate (males only)",
        "oncologist": "prostate-cancer"
    },
    {
        "wiki": "Breast_cancer",
        "county": "Breast",
        "oncologist": "breast-cancer"
    },
    {
        "wiki": "Colorectal_cancer",
        "county": "Colon & Rectum	",
        "oncologist": "colon-rectal-cancer"
    },
    {
        "wiki": "Uterine_cancer",
        "county": "Corpus Uteri (females only)",
        "oncologist": "gynecological-cancer/endometrial-uterine-cancer"
    },
    {
        "wiki": "Thyroid_cancer",
        "county": "Thyroid",
        "oncologist": "thyroid-cancer"
    },
    {
        "wiki": "Kidney_cancer",
        "county": "Kidney and Renal Pelvis",
        "oncologist": "kidney-cancer"
    },
    {
        "wiki": "Melanoma",
        "county": "Melanoma of the Skin",
        "oncologist": "melanoma"
    },
    {
        "wiki": "Non-Hodgkin_lymphoma",
        "county": "Non-Hodgkin Lymphoma",
        "oncologist": "lymphoma"
    },
    {
        "wiki": "Bladder_cancer",
        "county": "Urinary Bladder, invasive and in situ",
        "oncologist": "bladder-cancer"
    },
    {
        "wiki": "Cervical_cancer",
        "county": "Cervix Uteri (females only)",
        "oncologist": "gynecological-cancer/cervical-cancer"
    },
    {
        "wiki": "Pancreatic_cancer",
        "county": "Pancreas",
        "oncologist": "pancreas-cancer"
    },
    {
        "wiki": "Chronic_myelomonocytic_leukemia",
        "county": "Myeloid and Monocytic Leukemia",
        "oncologist": "leukemia"
    },
    {
        "wiki": "Vulvar_cancer",
        "county": "Vulva (females only)",
        "oncologist": "gynecological-cancer/vulvar-cancer"
    },
    {
        "wiki": "Ovarian_cancer",
        "county": "Ovary (females only)",
        "oncologist": "gynecological-cancer/ovarian-cancer"
    },
    {
        "wiki": "Multiple_myeloma",
        "county": "Myeloma",
        "oncologist": "myeloma"
    },
    {
        "wiki": "Stomach_cancer",
        "county": "Stomach",
        "oncologist": "stomach-cancer"
    },
    {
        "wiki": "Liver_cancer",
        "county": "Liver and Intrahepatic Bile Duct",
        "oncologist": "liver-cancer"
    },
    {
        "wiki": "Brain_tumor",
        "county": "Brain",
        "oncologist": "brain-cancer"
    },
    {
        "wiki": "Oropharyngeal_cancer",
        "county": "Tonsil",
        "oncologist": "head-neck-cancer/oral-cancer"
    },
    {
        "wiki": "Testicular_cancer",
        "county": "Testis (males only)",
        "oncologist": "https://ukhealthcare.uky.edu/services/testicular-cancer",
        "diagnosis": "https://ukhealthcare.uky.edu/testicular-cancer-diagnosis"
    },
    {
        "wiki": "Esophageal_cancer",
        "county": "Esophagus",
        "oncologist": "esophageal-cancer"
    },
    {
        "wiki": "Small_intestine_cancer",
        "county": "Small Intestine",
        "oncologist": "appendix"
    },
    {
        "wiki": "Laryngeal_cancer",
        "county": "Larynx",
        "oncologist": "voice-box-cancer"
    },
    {
        "wiki": "Oral_cancer",
        "county": ["Tongue", "Gum and Other Mouth"],
        "oncologist": "head-neck-cancer/oral-cancer"
    },
    {
        "wiki": "Anal_cancer",
        "county": "Anus, Anal Canal and Anorectum",
        "oncologist": "anal"
    },
    {
        "wiki": "Hodgkin_lymphoma",
        "county": "Hodgkin Lymphoma",
        "oncologist": "lymphoma"
    },
    {
        "wiki": "Gallbladder_cancer",
        "county": "Gallbladder",
        "oncologist": "gallbladder-cancer"
    },
    {
        "wiki": "Uveal_melanoma",
        "county": "Eye & Orbit",
        "oncologist": "N/A"
    },
    {
        "wiki": "Mesothelioma",
        "county": "Mesothelioma",
        "oncologist": "mesothelioma"
    },
    {
        "wiki": "Cholangiocarcinoma",
        "county": "Other Biliary",
        "oncologist": "liver-cancer"
    },
    {
        "wiki": "Penile_cancer",
        "county": "Penis (males only)",
        "oncologist": "penile",
        "diagnosis": "https://ukhealthcare.uky.edu/markey-cancer-center/cancer-types/penile-cancer/diagnosis"
    },
    {
        "wiki": "Thymoma",
        "county": "Other Endocrine including Thymus",
        "oncologist": "N/A"
    },
    {
        "wiki": "Neuroendocrine_tumor",
        "county": "Other Endocrine including Thymus",
        "oncologist": "neuroendocrine-cancer"
    },
    {
        "wiki": "Tongue_cancer", // Renamed to Tongue_cancer Manually in final, originally Oral_cancer for actual page
        "county": "Tongue",
        "oncologist": "tongue-cancer"
    },
    {
        "wiki": "Ceruminous_adenocarcinoma",
        "county": "Nose, Nasal Cavity, & Middle Ear",
        "oncologist": "ear"
    },
    {
        "wiki": "Skin_cancer",
        "county": "Other Non-Epithelial Skin",
        "oncologist": "skin-cancer"
    },
    {
        "wiki": "Bone_tumor",
        "county": "Bones & Joints",
        "oncologist": "bone-cancer"
    },
    {
        "wiki": "Adrenal_tumor",
        "county": "Other Endocrine including Thymus",
        "oncologist": "https://ukhealthcare.uky.edu/services/adrenal-cancer",
        "diagnosis": "https://ukhealthcare.uky.edu/adrenal-cancer-diagnosis"
    },
    {
        "wiki": "Paranasal_sinus_and_nasal_cavity_cancer",
        "county": "Nose, Nasal Cavity, & Middle Ear",
        "oncologist": "nasal-sinus-cancer"
    },
    {
        "wiki": "Salivary_gland_tumour",
        "county": "Salivary Gland",
        "oncologist": "head-neck-cancer/salivary-gland-tumors"
    },
    {
        "wiki": "Myelodysplastic_syndrome",
        "county": "Bones & Joints",
        "oncologist": "myelodysplastic-syndrome"
    },
    {
        "wiki": "Sarcoma",
        "county": "Kaposi Sarcoma",
        "oncologist": "sarcoma"
    },
    {
        "wiki": "Chordoma",
        "county": "Bones & Joints",
        "oncologist": "N/A"
    },
    {
        "wiki": "Chondrosarcoma",
        "county": "Bones & Joints",
        "oncologist": "N/A"
    },
    {
        "wiki": "Leiomyosarcoma",
        "county": "Soft Tissue Including Heart",
        "oncologist": "N/A"
    },
    {
        "wiki": "Rhabdomyosarcoma",
        "county": "Soft Tissue Including Heart",
        "oncologist": "N/A"
    },
    {
        "wiki": "Neuroblastoma",
        "county": "Other Endocrine including Thymus",
        "oncologist": "N/A"
    },
    {
        "wiki": "Medulloblastoma",
        "county": "Brain",
        "oncologist": "N/A"
    },
    {
        "wiki": "Parathyroid_carcinoma",
        "county": "Thyroid",
        "oncologist": "N/A"
    },
    {
        "wiki": "Wilms%27_tumor",
        "county": "Kidney and Renal Pelvis",
        "oncologist": "N/A"
    }
];

export default pageTitles;