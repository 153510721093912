/* eslint-disable react/prop-types */
import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import useImage from "../../utils/useImage";

/**
 * Creates a simple card taking in an object containing data for an Oncologist
 * such as an object straight from utils/dummyData.js
 * @param {Object} param 
 * @returns 
 */
const OncologistCard = ({ doctor }) => {
    const ratingText = doctor.rating === "None" ? "No Ratings" : doctor.rating + "/5★";
    const { image } = useImage(doctor.image);

    return (
        <Card className="text-center p-2 mt-2 mb-2 ms-auto me-auto oncologistCard">
            <Card.Img src={image} className="innerInstanceCardImage" />
            <Card.Body>
                <Card.Header>
                    <a href={"../../oncologists/" + doctor.id}>
                        {doctor.name}
                    </a>
                </Card.Header>
                <ListGroup className="text-start">
                    <ListGroupItem>Rating: {ratingText}</ListGroupItem>
                    <ListGroupItem>
                        <label>Degree</label>
                        <ul>
                            {doctor.background.degree.map((x, i) => {
                                return <li key={"degree" + i}>{x}</li>;
                            })}
                        </ul>
                    </ListGroupItem>
                    <ListGroupItem>
                        <label>Conditions</label>
                        <ul>
                            {doctor.conditions.map((x, i) => {
                                return <li key={"conditions" + i}>{x.type}</li>;
                            })}
                        </ul>
                    </ListGroupItem>
                </ListGroup>
            </Card.Body>
        </Card>
    );
};

export default OncologistCard;
