/* eslint-disable react/prop-types */

import { React, useState } from "react";
import { Button } from "react-bootstrap";

const SortButton = ({ id, eventClick }) => {
    let [direction, setDirection] = useState("neutral");
    let [text, setText] = useState("-");
    console.log(eventClick);
    if (eventClick == undefined) {
        eventClick = () => {};
    }

    function flipButton() {
        if (direction == "desc") {
            setDirection("asc");
            setText("▲");
        } else if (direction == "asc") {
            setDirection("neutral");
            setText("-");
        } else {
            setDirection("desc");
            setText("▼");
        }
    }

    return (
        <Button
            onClick={() => {
                flipButton();
                eventClick();
            }}
            direction={direction}
            id={id}
        >
            {text}
        </Button>
    );
};

export default SortButton;
