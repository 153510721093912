import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, ListGroup, ListGroupItem, Row, Col } from "react-bootstrap";
import useImage from "../utils/useImage";
import "../styles/types.css";
import OncologistCard from "../components/oncologists/OncologistCard";
import CountyCard from "../components/counties/CountyCard";

import axios from "axios";
import requestPrefix from "../utils/requestPrefix";

const Type = () => {
    let params = useParams();
    const cancerType = params.typeName;
    document.title = cancerType;
    const [oncologistData, setOncologistData] = useState(null);
    const [cancerData, setCancerData] = useState(null);
    const [countyData, setCountyData] = useState(null);

    const { image } = useImage(`types/${cancerType}.png`);

    /**
     * Useful for dynamically giving emphasis to certain words
     * in a text.
     * @param {String} text The text you wish to make text before : bold
     * @param {String} key a key for the element due to .map
     * @returns
     */
    function emboldenTextList(text, key) {
        // Used for making content easier to read
        let index = text.indexOf(":");
        if (index < 0) {
            index = -1;
        }
        return (
            <li key={key}>
                <b>{text.substring(0, index + 1)}</b>
                {text.substring(index + 1)}
            </li>
        );
    }

    /**
     * Renders OncologistCards for "Relevant Oncologists" for this
     * type of cancer.
     * @returns A list of OncologistCard Elements
     */
    function renderOncologists() {
        // let classifications = typeData.classifications.map((x) => x.toLowerCase());
        // Only include relevant doctors
        return oncologistData.map((doctor, i) => {
            return (
                <Col key={"doctor" + i}>
                    <OncologistCard doctor={doctor} />
                </Col>
            );
        });
    }

    /**
     * Currently only simply sorts counties by their cancer rates for this type
     * but also needs limiting in the future.
     * @returns A list of CountyCard Elements
     */
    function renderCounties() {
        // TODO: Limit the amount of counties
        return countyData.map((county, i) => {
            return (
                <Col key={"county" + i} className="m-0 p-0">
                    <CountyCard county={county} cancerSearchName={cancerData.search} />
                </Col>
            );
        });
    }

    function nameFormat(name) {
        let arr = name.replaceAll("_", " ").split(" ");
        arr = arr.map((str) => {
            return str[0].toUpperCase() + str.substring(1);
        });
        return arr.join(" ");
    }

    useEffect(() => {
        const fetchOncologistData = async (cData) => {
            if (oncologistData) {
                return;
            }
            try {
                let oncologistIds = cData.relatedOncologists;
                let outData = [];
                for (let id of oncologistIds) {
                    const response = await axios.get(`${requestPrefix}/oncologist/${id}`);
                    outData.push(response.data);
                }
                setOncologistData(outData);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        const fetchCancerData = async () => {
            if (cancerData) {
                return;
            }
            try {
                const response = await axios.get(`${requestPrefix}/cancer_types/${cancerType}`);
                let data = response.data;
                setCancerData(data);
                fetchOncologistData(data);
                fetchCountyData(data);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        const fetchCountyData = async (cData) => {
            if (countyData) {
                return;
            }
            try {
                let countyNames = cData.relatedCounties;
                let outData = [];
                for (let name of countyNames) {
                    const response = await axios.get(`${requestPrefix}/counties/${name}`);
                    outData.push(response.data);
                }
                setCountyData(outData);
            } catch (error) {
                console.error("Error fetching county data:", error);
            }
        };
        fetchCancerData();
    });

    if (oncologistData == null || cancerData == null || countyData == null) {
        return <>Still Loading...</>;
    }

    return (
        <div className="app">
            <Container>
                <Card className="text-center sideBar p-3 rounded-0 border border-dark">
                    <Card.Img src={image} width="90%" className="sidebarImage" />
                    <Card.Body>
                        <Card.Title>{nameFormat(cancerType)}</Card.Title>
                        <ListGroup className="text-start">
                            <ListGroupItem>
                                <label>Frequency</label>
                                <ul>
                                    <li>{cancerData.frequency.toLocaleString()}</li>
                                </ul>
                            </ListGroupItem>
                            <ListGroupItem>
                                <label>Classifications</label>
                                <ul>
                                    {cancerData.classifications.map((x) => {
                                        return <li key={x}>{x}</li>;
                                    })}
                                </ul>
                            </ListGroupItem>
                            <ListGroupItem>
                                <label>Parts of Body Affected</label>
                                <ul>
                                    {cancerData.partOfBody.map((x) => {
                                        return <li key={x}>{x}</li>;
                                    })}
                                </ul>
                            </ListGroupItem>
                            <ListGroupItem>
                                <label>Specialties</label>
                                <ul>
                                    {cancerData.specialty.map((x) => {
                                        return <li key={x}>{x}</li>;
                                    })}
                                </ul>
                            </ListGroupItem>
                        </ListGroup>
                    </Card.Body>
                    <iframe
                        width="80%"
                        src={cancerData.youtube.replace("/watch?v=", "/embed/")}
                        title="YouTube video player"
                        allow="encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>
                </Card>
                <div>
                    <p className="fs-1 fw-bold header">{nameFormat(cancerType)}</p>
                    {/*Dynamically break up the description for visual cleanliness.*/}
                    {cancerData.description
                        .replaceAll("\t", " — ")
                        .split("\n")
                        .map((str, i) => (
                            <p key={"type" + i} className="fs-5">
                                {str}
                            </p>
                        ))}
                    <p className="fs-2 fw-bold header">Symptoms</p>
                    <ul className="fs-5">
                        {/*Creates a list of symptoms*/}
                        {cancerData.symptoms.map((x, i) => {
                            return <li key={"symptom" + i}>{x}</li>;
                        })}
                    </ul>
                    <p className="fs-2 fw-bold header">Risk Factors</p>
                    <ul className="fs-5">
                        {cancerData.riskFactors.map((x, i) => {
                            return emboldenTextList(x, "risk" + i);
                        })}
                    </ul>
                    <p className="fs-2 fw-bold header">Diagnosis Methods</p>
                    <ul className="fs-5">
                        {cancerData.diagnosisMethods.map((x, i) => {
                            return emboldenTextList(x, "diagnosis" + i);
                        })}
                    </ul>
                </div>
                <Row className="text-center relatedRow" xs={1}>
                    <Row>
                        <h3 className="fw-bold header text-center">Relevant Counties</h3>
                        <Row xs={1} md={2} lg={4} style={{ justifyContent: "center" }}>
                            {renderCounties()}
                        </Row>
                    </Row>
                    <Row>
                        <h3 className="fw-bold header text-center">Related Oncologists</h3>
                        <Row xs={1} md={2} lg={2} style={{ justifyContent: "center" }}>
                            {renderOncologists()}
                        </Row>
                    </Row>
                </Row>
            </Container>
        </div>
    );
};

export default Type;
