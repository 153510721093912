import React, { useEffect } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js library
import PropTypes from 'prop-types';

const TrendLineChart = ({dataList}) => {
  useEffect(() => {
    // Data for the chart
    const data = {
      labels: ['2016', '2017', '2018', '2019', '2020'],
      datasets: [{
        label: 'Cancer Rate per 100,000 people',
        data: dataList, // Sample data
        borderColor: 'blue',
        backgroundColor: 'transparent',
        borderWidth: 2,
        type: 'line', // Set the chart type to line
        fill: false, // Do not fill the area under the line
        tension: 0.4 // Adjust the curve tension
      }]
    };

    // Configuration options
    const options = {
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: 'Cancer Rate Trend Line Chart'
        }
      }
    };

    // Get the canvas element
    const ctx = document.getElementById('trend-line-chart');

    // Create the chart
    new Chart(ctx, {
      type: 'line',
      data: data,
      options: options
    });
  }, []); // Empty dependency array to run the effect only once

  return (
    <div>
      {/* Canvas element for the chart */}
      <canvas id="trend-line-chart" width="600" height="400"></canvas>
    </div>
  );
};

TrendLineChart.propTypes = {
    dataList: PropTypes.array.isRequired // Example: Ensure dataList is an array and is required
};

export default TrendLineChart;
