import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/Logo.png";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../styles/navbar.css";
import SearchBar from "./SearchBar";

function NavbarElem() {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expand="lg" expanded={expanded}>
            <Container className="navContainer">
                <Navbar.Brand as={Link} to="/" className="navbarBrand">
                    <img src={Logo} width="50" height="50" alt="Logo" className="logo" />
                    <span className="brand-text">
                        <strong>Bluegrass</strong>
                        <em>Cancer</em>
                        <strong>Navigator</strong>
                    </span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto navList" onClick={() => setExpanded(false)}>
                        <Nav.Link as={Link} to="/">
                            Home
                        </Nav.Link>
                        <Nav.Link as={Link} to="/about">
                            About
                        </Nav.Link>
                        <Nav.Link as={Link} to="/types">
                            Cancer
                        </Nav.Link>
                        <Nav.Link as={Link} to="/oncologists">
                            Oncologists
                        </Nav.Link>
                        <Nav.Link as={Link} to="/counties">
                            Counties
                        </Nav.Link>
                        <Nav.Link as={Link} to="/visualizations">
                            Visualizations
                        </Nav.Link>
                        <Nav.Link as={Link} to="/provider_visualizations">
                            Provider Visualizations
                        </Nav.Link>
                        <SearchBar />
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default NavbarElem;

