import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../styles/index.css"
const Layout = () => {
    return (
        <>
            <Navbar /> 
            <div className="outletWrapper">
                <Outlet />
            </div>
        </>
    );
};
export default Layout;
