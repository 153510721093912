/* eslint-disable react/prop-types */
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import useImage from "../utils/useImage";
// import { highlightText } from "../utils/highlight";
import HighlightText from "../utils/highlight";
// import { countyData } from "../utils/countyData";
// const countyData = require("../utils/countyData.json");

const CountiesCard = ({ county, query }) => {
    const link = "../county/" + county.name;
    var instance = county;
    var population = instance.totalPopulation.toLocaleString();
    var numberOfCases = instance.numberOfCases.toLocaleString();
    var crudeCancerRate = instance.crudeCancerRate;
    var ageAdjustedRate = instance.ageAdjustedRate;
    var imageAddr = county.image;
    const { image } = useImage(imageAddr)
    return (
        <Card className="modelCard">
            <Card.Img
                variant="top"
                src={image}
                height={"auto"}
                style={{ objectFit: "stretch", aspectRatio: "1" }}
                className="instanceCardImage"
            />
            <Card.Body>
                {/* <Card.Title>{HighlightText(county.name, query)}</Card.Title> */}
                <Card.Title><HighlightText content={county.name} query={query}/></Card.Title>
                <Card.Text>
                    <strong>Population: </strong>
                    <HighlightText content={population} query={query}/>
                    <br />
                    <strong>Annual Cases: </strong>
                    <HighlightText content={numberOfCases} query={query}/>
                    <br />
                    <strong>Crude Cancer Rate: </strong>
                    <HighlightText content={crudeCancerRate} query={query}/>
                    <br />
                    <strong>Age Adjusted Rate: </strong>
                    <HighlightText content={ageAdjustedRate} query={query}/>
                </Card.Text>
                <Button href={link}>More Info</Button>
            </Card.Body>
        </Card>
    );
}


CountiesCard.propTypes = {
    name: PropTypes.string,
};
export default CountiesCard;