import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

const SearchBar = () => {
    const [query, setQuery] = useState('');

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (query.trim() !== '') {
            // Redirect to the search results page
            window.location.href = `/search/${encodeURIComponent(query)}`;
        }
    };

    return (
        <Container className="justify-content-right align-items-right ms-auto" >
            <Form onSubmit={handleSubmit} className="d-flex">
                <Form.Control
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onChange={handleChange}
                    className="mr-2"
                />
                <Button variant="primary" type="submit">Search</Button>
            </Form>
        </Container>
    );
};

export default SearchBar;
