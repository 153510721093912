import React, { useEffect, useState } from "react";
import { Row, Col, Button, FloatingLabel, Form } from "react-bootstrap";
import OncologistsCard from "../components/OncologistsCard";
import axios from "axios";
import requestPrefix from "../utils/requestPrefix";
import Multiselect from "multiselect-react-dropdown";
import SortButton from "../components/SortButton";
import { conditionsFilter, zipCodeFilter, clinicFilter, schoolFilter } from "../utils/conditionsFilter";

function Oncologists() {
    document.title = "Oncologists";
    const [currentPage, setCurrentPage] = useState(1);
    const [oncologistData, setOncologistData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const cardsPerPage = 9;

    let [conditionsFilterSelected, setConditionsFilterSelected] = useState([]);
    let [zipCodeFilterSelected, setZipCodeFilterSelected] = useState([]);
    let [clinicFilterSelected, setClinicFilterSelected] = useState([]);
    let [schoolFilterSelected, setSchoolFilterSelected] = useState([]);

    useEffect(() => {
        const fetchOncologistData = async () => {
            try {
                const response = await axios.get(`${requestPrefix}/oncologist`);
                setOncologistData(response.data);
            } catch (error) {
                console.error("Error fetching oncologist data:", error);
            }
        };
        fetchOncologistData();
    }, []);

    if (oncologistData == null) {
        return <>Still Loading...</>;
    }

    // Pagination
    const totalPages = Math.ceil(oncologistData.length / cardsPerPage);
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = oncologistData.slice(indexOfFirstCard, indexOfLastCard);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleFirstPage = () => {
        setCurrentPage(1)
    };
    
    const handleLastPage = () => {
        setCurrentPage(totalPages)
    };

    // async function makeSearch() {
        
    //     if (!searchQuery) {
    //         // If searchQuery is empty, set oncologistData to its initial state
    //         setCurrentPage(1);
    //         setOncologistData(oncologistData);
    //         return;
    //     }
    
    //     const body = {
    //         searchQuery: searchQuery
    //     };


    //     const response = await axios.post(`${requestPrefix}/oncologist/searchbar`, body, {
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });
    //     if (response.status == 200){
    //         setCurrentPage(1);
    //         setOncologistData(response.data);
    //     }
    // }

    async function makeFetch() {
        const conditions = conditionsFilterSelected.map((x) => x.name);
        const zipCodes = zipCodeFilterSelected.map((x) => x.name);
        const clinics = clinicFilterSelected.map((x) => x.name);
        const schools = schoolFilterSelected.map((x) => x.name);
        // if (!searchQuery) {
        //     // If searchQuery is empty, set oncologistData to its initial state
        //     setCurrentPage(1);
        //     setOncologistData(oncologistData);
        //     return;
        // }

        const body = {
            searchQuery: searchQuery,
            filterParams: {
                rating: Number(document.getElementById("ratingFilter").value),
                conditions: conditions,
                zipCode: zipCodes,
                clinic: clinics,
                school: schools
            },
            sortParams: {
                rating: document.getElementById("ratingButton").getAttribute("direction")
            }
        };
        const response = await axios.post(`${requestPrefix}/oncologist/search`, body, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status == 200) {
            setCurrentPage(1);
            setOncologistData(response.data);
        }
    }

    return (
        <div className="app">
            <h1 className="text-center">Oncologists in Kentucky</h1>
            <Row className="justify-content-center align-items-center" style={{ width: "88.25vw", margin: "auto" }}>
                <Col>
                    <FloatingLabel
                        label="Search"
                        controlId="search"
                        style={{ width: "42.25vw", margin: "auto" }}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <Button onClick={makeFetch}>Search</Button>
                </Col>
            </Row>
            <Row
                xs={1}
                sm={2}
                md={4}
                lg={4}
                style={{ padding: "1vw", width: "90vw", margin: "auto"}}
                className="justify-content-center align-items-center"
            >
                <Col className="filterInput">
                    <Multiselect
                        options={conditionsFilter.options} // Options to display in the dropdown
                        selectedValues={conditionsFilterSelected} // Preselected value to persist in dropdown
                        onSelect={(selectedList) => {
                            setConditionsFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setConditionsFilterSelected(selectedList);
                        }}
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Conditions"
                    />
                </Col>
                <Col className="filterInput">
                    <Multiselect
                        options={zipCodeFilter.options}
                        selectedValues={zipCodeFilterSelected}
                        onSelect={(selectedList) => {
                            setZipCodeFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setZipCodeFilterSelected(selectedList);
                        }}
                        displayValue="name"
                        placeholder="Zip Codes"
                    />
                </Col>
                <Col className="filterInput">
                    <Multiselect
                        options={clinicFilter.options}
                        selectedValues={clinicFilterSelected}
                        onSelect={(selectedList) => {
                            setClinicFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setClinicFilterSelected(selectedList);
                        }}
                        displayValue="name"
                        placeholder="Clinics"
                    />
                </Col>
                <Col className="filterInput">
                    <Multiselect
                        options={schoolFilter.options}
                        selectedValues={schoolFilterSelected}
                        onSelect={(selectedList) => {
                            setSchoolFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setSchoolFilterSelected(selectedList);
                        }}
                        displayValue="name"
                        placeholder="Schools"
                    />
                </Col>
                <Col className="filterInput">
                    <FloatingLabel label="Min Rating (4.5)" controlId="ratingFilter">
                        <Form.Control type="number"></Form.Control>
                    </FloatingLabel>
                </Col>
                <Col className="filterInput" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label style={{ marginRight: "2%" }}>Rating</label>
                    <SortButton id="ratingButton" />
                </Col>
            </Row>
            <p className="text-center fs-6">
                Showing {currentCards.length} of {oncologistData.length} Results
            </p>
            <Row
                xs={1}
                sm={1}
                md={2}
                lg={3}
                xl={3}
                className="g-2 justify-content-center"
                style={{ width: "80%", margin: "auto" }}
            >
                {currentCards.map((type, i) => {
                    return (
                        <Col key={"oncologist" + i}>
                            <OncologistsCard oncologist={type} query={searchQuery}/>
                        </Col>
                    );
                })}
            </Row>
            <div className="text-center mt-3">
                <Button variant="primary" disabled={currentPage === 1} onClick={handleFirstPage}>
                    First
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === 1} onClick={handlePreviousPage}>
                    {"<"}
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === totalPages} onClick={handleNextPage}>
                    {">"}
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === totalPages} onClick={handleLastPage}>
                    Last
                </Button>{" "}
            </div>
            <p className="text-center fs-6">
                Page {currentPage} of {totalPages}
            </p>
        </div>
    );
}
export default Oncologists;