const TEAM = [
    {
        name: "Numan Osagie",
        alt_name: "nosagie1",
        user_id: 16874456,
        image: "members/numan-headshot.jpg",
        commits: 0,
        tests: 0,
        issues: 0,
        email: "nosagie@utexas.edu",
        bio: "Hi, I'm Numan. I'm a junior CS major at UT. I enjoy boxing, hanging out with friends, and creative media. Fun fact: I've recently purchased my first camera.",
        role: "Fullstack, Graphics"
    },
    {
        name: "Simon Stone",
        alt_name: "simonstoneatx",
        user_id: 19864479,
        image: "members/Simon.jpg",
        commits: 0,
        tests: 0,
        issues: 0,
        email: "simonstone@utexas.edu",
        bio: "I'm Simon, a senior at UT majoring in computer science. Some of my hobbies are skateboarding, photography, and hanging out with friends.",
        role: "Fullstack"
    },
    {
        name: "Donald Thai",
        alt_name: "Donald",
        user_id: 19737134,
        image: "members/donald.jpg",
        commits: 0,
        tests: 28,
        issues: 0,
        email: "donaldthai@utexas.edu",
        bio: "Hey! I'm Donald, and I'm a junior cs major. I really enjoy playing sports, going to the gym, and reading. I also recently started learning how to cook, which has been pretty fun so far.",
        role: "Fullstack"
    },
    {
        name: "Davinderpal Toor",
        alt_name: "EmperorBob7",
        user_id: 19730886,
        image: "members/Davinderpal.jpg",
        commits: 0,
        tests: 23,
        issues: 0,
        email: "davindertoor77@gmail.com",
        bio: "Hello, I'm a Junior CS major and in my free time I enjoy watching anime, baking, and cooking. One series I recommend is World Trigger by Daisuke Ashihara.",
        role: "Fullstack, AWS, and Postman"
    },
    {
        name: "HeSong Wang",
        alt_name: "Hersoncpp",
        user_id: 19764179,
        image: "members/Herson.jpg",
        commits: 0,
        tests: 0,
        issues: 0,
        email: "hwangem@connect.ust.hk",
        bio: "Hello, I'm Herson, an exchange CS student from HKUST. I'm always ready to learn more from my teammates. In my spare time, I'm into fps games and SOTA AI developments.",
        role: "Fullstack, SQL"
    }
];

function resetTeam() {
    // Needed to avoid values infinitely increasing
    TEAM.forEach(x => {
        x.commits = 0;
        x.issues = 0;
    });
}

export { TEAM, resetTeam };
