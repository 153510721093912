import { React, useState, useEffect } from "react";
import { TEAM, resetTeam } from "../components/about/Team.js";
import { TECH_STACK } from "../components/about/TechStack.js";
import TechStack from "../components/about/TechStack.jsx";
import Member from "../components/about/Member.jsx";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import ValleyOfTheDrums from "../images/about/ValleyOfTheDrums.jpg";

function About() {
    document.title = "About";
    const [totalCommits, setTotalCommits] = useState(0);
    const [totalIssues, setTotalIssues] = useState(0);

    /**
     * Use the GitLab API to get the number of commits and fill the TEAM's data
     * using the values. Member's may wish to specify an "alt_name" as mentioned
     * below.
     */
    async function getCommits() {
        // Use Fetch to dynamically update commits
        const request = await fetch("https://gitlab.com/api/v4/projects/54609874/repository/contributors");
        let result = await request.json();
        let total = 0;
        result.forEach((obj) => {
            TEAM.forEach((member) => {
                // Alt Names are for people with commits not linked to their name like "EmperorBob7"
                if (obj.name == member.name || obj.name == member.alt_name) {
                    member.commits += obj.commits;
                    total += obj.commits;
                }
            });
        });
        setTotalCommits(total);
    }

    /**
     * Use the GitLab API to fill in the number of issues closed per Team Member.
     */
    async function getIssues() {
        let total = 0;
        for (const member of TEAM) {
            const request = await fetch(
                "https://gitlab.com/api/v4/projects/54609874/issues_statistics?author_id=" + member.user_id
            );
            let result = await request.json();
            // We want the issues closed for each member
            member.issues = result.statistics.counts.closed;
            total += result.statistics.counts.all;
        }
        setTotalIssues(total);
    }

    /**
     * Returns a React Element containing Columns of each Member's data.
     * TODO: TestCases
     */
    function renderTeam() {
        return TEAM.map((member, index) => {
            return (
                <Col key={index} md={4}>
                    <Member
                        name={member.name}
                        imageSrc={member.image}
                        commits={member.commits || 0}
                        issues={member.issues || 0}
                        email={member.email}
                        bio={member.bio}
                        role={member.role}
                        unitTests={member.tests || 0}
                    />
                </Col>
            );
        });
    }

    /**
     * Returns a list of React Columns containing cards for the Technologies used
     * in this project.
     */
    function renderTechStack() {
        return TECH_STACK.map((member, index) => {
            return (
                <Col key={index}>
                    <TechStack name={member.name} imageSrc={member.image} description={member.description} />
                </Col>
            );
        });
    }

    /**
     * UseEffect allows us to make asynchronous calls when we need
     * to do Client Side Rendering (such as for the API information).
     */
    useEffect(() => {
        resetTeam();
        getCommits();
        getIssues();
    }, []);

    return (
        <div className="app">
            <Container className="text-center mt-2">
                <h1>About Bluegrass Cancer Navigation</h1>
                <hr />
            </Container>
            <Container className="text-center">
                <Row xs={1} xl={2} className="justify-content-center align-items-center">
                    <Col>
                        <p>
                            The Valley of the Drums is a toxic waste site in the town of Brooks in Bullitt
                            County, Kentucky. In 1978, the site was found to have a whopping 100,000 toxic
                            waste drums by the Kentucky Department of Natural Resources and Environmental
                            Protection.
                            <br />
                            The site was referenced in the congress hearings that lead to the creation of the
                            Superfund act, which was passed in 1980, and sent aid to allow toxic waste sites
                            such as The Valley of the Drums to be cleaned up. Cleanup of the site took place
                            from 1983 to 1990, but the effects haven’t been so easily cleaned up.
                            <br />
                            <br />
                            Kentucky, due to events like this and high rates of poverty, has the highest
                            cancer rate per capita in the country, yet it lacks the robust cancer treatment
                            facilities that exist in other, richer states.
                            <br />
                            While scraping data for our project we found that there’s a large disparity in
                            cancer data for Kentucky as opposed to other states. Not only were the numbers
                            sometimes misleading or incorrectly labelled, we also found that there wasn’t
                            explanations for what terms meant either. To get past this we referenced different
                            sourced to find out what was the most accurate and also tried to include as many
                            definitions as we could.
                        </p>
                    </Col>
                    <Col className="align-items-center">
                        <img src={ValleyOfTheDrums} className="image" />
                    </Col>
                </Row>
            </Container>
            <Container className="p-1 text-center teamContainer">
                <h1 className="text-center"> Meet the Bluegrass Team! </h1>
                <hr />
                <Container className="mt-5">
                    <Row className="g-2 justify-content-center" xs={1} sm={2} md={4} xl={5}>
                        {renderTeam()}
                    </Row>
                </Container>
                <p className="fs-3 text-center">
                    Total Commits: {totalCommits}
                    <br />
                    Total Issues: {totalIssues}
                </p>
            </Container>
            <Container className="text-center mb-2">
                <h1>Technology Used</h1>
                <hr />
                <Row className="g-2 justify-content-center" xs={1} sm={2} md={4} xl={5}>
                    {renderTechStack()}
                </Row>
                <h1>Data Sources</h1>
                <hr />
                <Row className="g-2 justify-content-center" xs={1} sm={2} md={4}>
                    <Card className="m-1">
                        <Card.Body>
                            <a href="https://www.cancer.gov/types">https://www.cancer.gov/types</a>
                        </Card.Body>
                    </Card>
                    <Card className="m-1">
                        <Card.Body>
                            <a href="https://en.wikipedia.org/api/rest_v1/">https://en.wikipedia.org/api/rest_v1/</a>
                        </Card.Body>
                    </Card>
                    <Card className="m-1">
                        <Card.Body>
                            <a href="https://developers.google.com/maps">https://developers.google.com/maps</a>
                        </Card.Body>
                    </Card>
                    <Card className="m-1">
                        <Card.Body>
                            <a href="https://statecancerprofiles.cancer.gov/map/map.withimage.php?21&county&001&001&00&0&01&0&1&5&0#results">https://statecancerprofiles.cancer.gov/incidencerates/index</a>
                        </Card.Body>
                    </Card>
                    <Card className="m-1">
                        <Card.Body>
                            <a href="https://www.cancer-rates.com/ky/">https://www.cancer-rates.com/ky/</a>
                        </Card.Body>
                    </Card>
                    <Card className="m-1">
                        <Card.Body>
                            <a href="https://ukhealthcare.uky.edu/search#doctors?s=eJyrVkrLTM1JiS9OLSrLTE6Nz8nMS1WyUjI1MjRXqgUAohQJ5Q%3D%3D">https://ukhealthcare.uky.edu</a>
                        </Card.Body>
                    </Card>
                </Row>
                <hr />
                <Row
                    className="g-2 text-center"
                    xs={1}
                    sm={2}
                    md={4}
                    xl={5}
                    style={{ width: "50vw", margin: "auto" }}
                >
                    <a
                        href="https://documenter.getpostman.com/view/32889204/2sA2r3Z69p"
                        className="m-auto"
                        style={{ width: "100%" }}
                        target="_"
                    >
                        <Button style={{ width: "100%" }}>API Documentation</Button>
                    </a>
                    <a
                        href="https://youtu.be/dGVBS_LJoz8?si=Fun-B-OmxwkURZ3t"
                        className="m-auto mt-2"
                        style={{ width: "100%" }}
                        target="_"
                    >
                        <Button style={{ width: "100%" }} variant="danger">PechaKucha Presentation</Button>
                    </a>
                </Row>
            </Container>
        </div>
    );
}
export default About;
