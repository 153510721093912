/* eslint-disable react/prop-types */

import React from "react";
import Card from "react-bootstrap/Card";
import "./reviewCard.css";

const ReviewCard = ({ text }) => {
    return (
        <Card className="reviewCardWrapper">
            <Card.Body>
                <Card.Text className="reviewCardText">“{text}”</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default ReviewCard;
