import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Card, ListGroup, ListGroupItem, Row, Col, Accordion } from "react-bootstrap";
import "../styles/counties.css";
import TrendLineChart from "../components/counties/trendchart";
import useImage from "../utils/useImage";
import OncologistCard from "../components/oncologists/OncologistCard";
import TypeCard from "../components/types/TypeCard";
import axios from "axios";
import requestPrefix from "../utils/requestPrefix";

const County = () => {
    let params = useParams();
    const name = params.name;
    document.title = name;

    const { image } = useImage(`counties/${name}.png`);
    const [oncologistData, setOncologistData] = useState(null);
    const [cancerData, setCancerData] = useState(null);
    const [countyData, setCountyData] = useState(null);

    function renderTypes() {
        return cancerData.map((type, i) => {
            return (
                <Col key={"type" + i}>
                    <TypeCard type={type} rate={countyData.cancer_rates[type.search]} />
                </Col>
            );
        });
    }

    function renderOncologists() {
        return oncologistData.map((doctor, i) => {
            return (
                <Col key={"doctor" + i}>
                    <OncologistCard doctor={doctor} />
                </Col>
            );
        });
    }

    useEffect(() => {
        const fetchOncologistData = async (cData) => {
            if (oncologistData) {
                return;
            }
            try {
                let oncologistIds = cData.relatedOncologists;
                let outData = [];
                for (let id of oncologistIds) {
                    const response = await axios.get(`${requestPrefix}/oncologist/${id}`);
                    outData.push(response.data);
                }
                setOncologistData(outData);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        const fetchCancerData = async (cData) => {
            if (cancerData) {
                return;
            }
            try {
                let cancerNames = cData.relatedTypes;
                let outData = [];
                for (let name of cancerNames) {
                    const response = await axios.get(`${requestPrefix}/cancer_types/${name}`);
                    outData.push(response.data);
                }
                setCancerData(outData);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        const fetchCountyData = async () => {
            if (countyData) {
                return;
            }
            try {
                const response = await axios.get(`${requestPrefix}/counties/${name}`);
                setCountyData(response.data);
                fetchOncologistData(response.data);
                fetchCancerData(response.data);
            } catch (error) {
                console.error("Error fetching county data:", error);
            }
        };
        fetchCountyData();
    });

    if (oncologistData == null || cancerData == null || countyData == null) {
        return <>Still Loading...</>;
    }

    return (
        <div className="app">
            <Container>
                <Card className="text-center sideBar p-3 m-3">
                    <Card.Img src={image} className="sideBarImg" />
                    <Card.Body>
                        <Card.Title>{countyData.name}</Card.Title>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>
                                <label>
                                    <b>Population:</b>
                                </label>
                                <span> {countyData.totalPopulation}</span>
                            </ListGroupItem>
                            <ListGroupItem>
                                <label>
                                    <b>Cases:</b>
                                </label>
                                <span> {countyData.numberOfCases}</span>
                            </ListGroupItem>
                            <ListGroupItem>
                                <label>
                                    <b>Cancer Rate:</b>
                                </label>
                                <span> {countyData.crudeCancerRate}</span>
                            </ListGroupItem>
                            <ListGroupItem>
                                <label>
                                    <b>Age Adjusted Rate:</b>
                                </label>
                                <span> {countyData.ageAdjustedRate}</span>
                            </ListGroupItem>
                        </ListGroup>
                    </Card.Body>
                </Card>
                <div>
                    <Row>
                        <p className="fs-2 fw-bold header">Description:</p>
                        <p>{countyData.description}</p>
                    </Row>
                    <Row>
                        <Col>
                            <p className="fs-2 fw-bold header">Location:</p>
                            <iframe
                                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_KEY}&q=${countyData.name} County, KY`}
                                className="locationImg"
                            ></iframe>
                        </Col>

                        <Col>
                            <p className="fs-2 fw-bold header">Cancer Rate TrendLine:</p>
                            <TrendLineChart dataList={countyData.trendData} />
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <p className="fs-2 fw-bold header mt-3">Cancer Rates:</p>
                    </Row>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Collapse</Accordion.Header>
                            <Accordion.Body>
                                <Row xs={2} sm={3} md={4} lg={5} xl={6}>
                                    {Object.keys(countyData.cancer_rates).map((key, i) => {
                                        let count = countyData.cancer_rates[key];
                                        return (
                                            <Col key={"rate" + i}>
                                                <Card>
                                                    <Card.Header>{key}</Card.Header>
                                                    <Card.Body>{count}</Card.Body>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <Row className="text-center mt-4" xs={1}>
                    <Row>
                        <h3 className="fw-bold header text-center">Prominent Cancer</h3>
                        <Row xs={1} md={2} lg={4} style={{ justifyContent: "center" }}>
                            {renderTypes()}
                        </Row>
                    </Row>
                    <Row>
                        <h3 className="fw-bold header text-center">Relevant Oncologists</h3>
                        <Row xs={1} md={2} lg={2} style={{ justifyContent: "center" }}>
                            {renderOncologists()}
                        </Row>
                    </Row>
                </Row>
            </Container>
        </div>
    );
};
export default County;
