import BluegrassStageImage from "../images/bluegrass-state.jpg";
import { Link } from "react-router-dom";
import React/*, { useState }*/ from "react";
// import axios from "axios";
// import requestPrefix from "../utils/requestPrefix";
import { Button, Col, Container, Row/*, FloatingLabel, Form*/ } from "react-bootstrap";
import "../styles/home.css";
// import OncologistsCard from "../components/OncologistsCard";
// import TypesCard from "../components/TypesCard";
// import CountiesCard from "../components/CountiesCard";
// import { highlightText } from "../utils/highlight";

function Home() {
    document.title = "Bluegrass Cancer Navigator";
    // const [searchQuery, setSearchQuery] = useState("");
    // const [countyData, setCountyData] = useState([]);
    // const [oncologistData, setOncologistData] = useState([]);
    // const [typeData, setTypeData] = useState([]);
    // async function makeSearch() {

    //     const body = {
    //         searchQuery: searchQuery
    //     };
    //     const response = await axios.post(`${requestPrefix}/searchbar`, body, {
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });
    //     if (response.status == 200){
    //         let data = response.data;
    //         // set oncologist data to the one whose "type"== "oncologist", and only contain the "data" part
            
    //         setOncologistData(data.filter((x) => x.type == "oncologist").map((x) => x.data));
    //         setTypeData(data.filter((x) => x.type == "cancer").map((x) => x.data));
    //         setCountyData(data.filter((x) => x.type == "county").map((x) => x.data));
    //         // also limit each model at most 4 instances

    //         setOncologistData(oncologistData.slice(0, 4));
    //         setTypeData(typeData.slice(0, 4));
    //         setCountyData(countyData.slice(0, 4));
    //     }
        
    // }
    return (
        <div className="app">
            <Container>
                <Row xs={1} md={2} lg={2} xl={2}>
                    <Col>
                        <Row>
                            <h1 className="homeHeader">
                                Navigating <span className="textSecondary">Cancer</span> in Bluegrass
                                Territory
                            </h1>
                        </Row>
                        <Row>
                            <p>
                                The Valley of the Drums is a toxic waste site in the town of Brooks in Bullitt
                                County, Kentucky. In 1978, the site was found to have a whopping 100,000 toxic
                                waste drums by the Kentucky Department of Natural Resources and Environmental
                                Protection. Kentucky, due to events like this and high rates of poverty, has
                                the highest cancer rate per capita in the country.
                            </p>
                        </Row>
                        <Row xs={1} sm={3} className="text-center">
                            <Col className="mb-2">
                                <Link to="/Types">
                                    <Button variant="success">Cancer Types</Button>
                                </Link>
                            </Col>
                            <Col className="mb-2">
                                <Link to="/Oncologists">
                                    <Button variant="warning">Oncologists</Button>
                                </Link>
                            </Col>
                            <Col className="mb-2">
                                <Link to="/Counties">
                                    <Button variant="danger">KY Counties</Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <img src={BluegrassStageImage} className="image" height="90%" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default Home;