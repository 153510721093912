/* eslint-disable react/prop-types */
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import useImage from "../utils/useImage";
import HighlightText from "../utils/highlight";

const TypesCard = ({ type, query }) => {
    const link = "../type/" + type.name;
    var instance = type;
    var classification = "";
    for (let i = 0; i < instance.classifications.length - 1; i++) {
        classification += instance.classifications[i] + ", ";
    }
    classification += instance.classifications[instance.classifications.length - 1];
    var frequency = instance.frequency.toLocaleString();
    var symptoms = "";
    for (let i = 0; i < instance.symptoms.length - 1; i++) {
        symptoms += instance.symptoms[i] + ", ";
    }
    symptoms += instance.symptoms[instance.symptoms.length - 1];
    var partOfBody = "";
    for (let i = 0; i < instance.partOfBody.length - 1; i++) {
        partOfBody += instance.partOfBody[i] + ", ";
    }
    partOfBody += instance.partOfBody[instance.partOfBody.length - 1];
    var specialty = "";
    for (let i = 0; i < instance.specialty.length - 1; i++) {
        specialty += instance.specialty[i] + ", ";
    }
    specialty += instance.specialty[instance.specialty.length - 1];
    var imageAddr = instance.image;
    const { image } = useImage(imageAddr);

    function nameFormat(name) {
        let arr = name.replaceAll("_", " ").split(" ");
        arr = arr.map(str => {
            return str[0].toUpperCase() + str.substring(1);
        });
        return arr.join(" ");
    }

    return (
        <Card className="modelCard">
            <Card.Img
                variant="top"
                src={image}
                height={"auto"}
                style={{ objectFit: "stretch", aspectRatio: "1" }}
                className="instanceCardImage"
            />
            <Card.Body>
                <Card.Title><HighlightText content={nameFormat(type.name)} query={query}/></Card.Title>
                <Card.Text>
                    <strong>Classifications: </strong>
                    <HighlightText content={classification} query={query}/>
                    <br />
                    <strong>Frequency: </strong>
                    <HighlightText content={frequency} query={query}/>
                    <br />
                    <strong>Common Symptoms: </strong>
                    <HighlightText content={symptoms} query={query}/>
                    <br />
                    <strong>Part of Body: </strong>
                    <HighlightText content={partOfBody} query={query}/>
                    <br />
                    <strong>Specialty: </strong>
                    <HighlightText content={specialty} query={query}/>
                </Card.Text>
                <Button href={link}>More Info</Button>
            </Card.Body>
        </Card>
    );
}

export default TypesCard;