/* eslint-disable react/prop-types */
import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import useImage from "../../utils/useImage";

const CountyCard = ({ county, cancerSearchName }) => {
    const { image } = useImage(county.image);

    function rateComponent() {
        if (cancerSearchName !== null) {
            return (
                <ListGroupItem>
                    Number of {cancerSearchName} Cases: {county.cancer_rates[cancerSearchName]}
                </ListGroupItem>
            );
        }
        return <p />;
    }

    return (
        <Card className="text-center pt-1 pb-1 mt-2 mb-2 ms-auto me-auto countyCard">
            <Card.Img src={image} className="innerInstanceCardImage" />
            <Card.Body>
                <Card.Header>
                    <a href={"../../county/" + county.name}>
                        {county.name}
                    </a>
                </Card.Header>
                <ListGroup className="text-start">
                    <ListGroupItem>Total Population: {county.totalPopulation}</ListGroupItem>
                    {rateComponent()}
                </ListGroup>
            </Card.Body>
        </Card>
    );
};

export default CountyCard;
