const TECH_STACK = [
    {
        "name": "React.JS",
        "image": "about/ReactImage.png",
        "description": "Front-End Framework"
    },
    {
        "name": "React Bootstrap",
        "image": "about/BootstrapImage.jpg",
        "description": "Bootstrap rebuilt for React"
    },
    {
        "name": "PostMan",
        "image": "about/PostmanImage.png",
        "description": "Public API Documentation"
    },
    {
        "name": "Slack",
        "image": "about/SlackImage.png",
        "description": "Team Communication"
    },
    {
        "name": "GitLab",
        "image": "about/GitlabImage.png",
        "description": "Repository for Collaboration"
    },
    {
        "name": "GitLab API",
        "image": "about/GitlabImage.png",
        "description": "Track work done by members"
    }
    ,
    {
        "name": "VSCode",
        "image": "about/VSCodeImage.png",
        "description": "Track work done by members"
    }
    ,
    {
        "name": "Zoom",
        "image": "about/ZoomImage.jpg",
        "description": "Virtual Meetings"
    },
    {
        "name": "Supabase",
        "image": "about/SupabaseImage.jpg",
        "description": "Database Host"
    },
    {
        "name": "Google Cloud",
        "image": "about/CloudImage.jpg",
        "description": "Backend Hosting"
    },
    {
        "name": "AWS Amplify",
        "image": "about/AmplifyImage.jpg",
        "description": "Frontend Hosting"
    },
    {
        "name": "YouYube Embeds",
        "image": "about/YoutubeImage.jpg",
        "description": "Video Embeds for Cancer Types"
    },
    {
        "name": "Google Maps API",
        "image": "about/MapsImage.jpg",
        "description": "Location Embeds for Oncologists and Counties"
    },
    {
        "name": "Chart.JS",
        "image": "about/ChartJSImage.jpg",
        "description": "County data plots"
    },
    {
        "name": "Flask",
        "image": "about/FlaskImage.jpg",
        "description": "Python server library for backend"
    },
    {
        "name": "Puppeteer.JS",
        "image": "about/PuppeteerImage.jpg",
        "description": "JS Framework for Data Scraping"
    },
    {
        "name": "Jest",
        "image": "about/JestImage.jpg",
        "description": "JS Framework for unit testing"
    },
    {
        "name": "Gunicorn",
        "image": "about/GunicornImage.jpg",
        "description": "Used for running the server on Google Cloud"
    },
    {
        "name": "SQL Alchemy",
        "image": "about/SQLAlchemyImage.jpg",
        "description": "Interacting with our Supabase Database in Python"
    },
    {
        "name": "Selenium",
        "image": "about/SeleniumImage.jpg",
        "description": "Tests that ran in the browser to ensure proper loading"
    },
    {
        "name": "Axios",
        "image": "about/AxiosImage.jpg",
        "description": "Frontend HTTP Requests"
    },
    {
        "name": "Docker",
        "image": "about/DockerImage.jpg",
        "description": "Consistent development for front and backend across machines"
    },
    {
        "name": "NPM",
        "image": "about/NPMImage.jpg",
        "description": "Package manager for dependencies"
    }
]

export { TECH_STACK };