const classificationFilter = {
    "options": [
        {
            "name": "small cell",
            "id": 0
        },
        {
            "name": "non-small cell",
            "id": 1
        },
        {
            "name": "adenocarcinoma",
            "id": 2
        },
        {
            "name": "small cell carcinoma",
            "id": 3
        },
        {
            "name": "neuroendocrine tumor",
            "id": 4
        },
        {
            "name": "transitional cell carcinoma",
            "id": 5
        },
        {
            "name": "sarcoma",
            "id": 6
        },
        {
            "name": "ductal carcinoma in situ",
            "id": 7
        },
        {
            "name": "triple-negative",
            "id": 8
        },
        {
            "name": "inflammatory",
            "id": 9
        },
        {
            "name": "paget disease",
            "id": 10
        },
        {
            "name": "angiosarcoma",
            "id": 11
        },
        {
            "name": "phyllodes tumor",
            "id": 12
        },
        {
            "name": "primary colorectal lymphomas",
            "id": 13
        },
        {
            "name": "gastrointenstinal stromal tumor",
            "id": 14
        },
        {
            "name": "leiomyosarcoma",
            "id": 15
        },
        {
            "name": "carcinoid tumor",
            "id": 16
        },
        {
            "name": "melanoma",
            "id": 17
        },
        {
            "name": "endometrial",
            "id": 18
        },
        {
            "name": "papillary",
            "id": 19
        },
        {
            "name": "follicular",
            "id": 20
        },
        {
            "name": "medullary",
            "id": 21
        },
        {
            "name": "anaplastic",
            "id": 22
        },
        {
            "name": "renal cell carcinoma (rcc)",
            "id": 23
        },
        {
            "name": "non clear cell renal carcinoma",
            "id": 24
        },
        {
            "name": "other",
            "id": 25
        },
        {
            "name": "superficial spreading",
            "id": 26
        },
        {
            "name": "nodular",
            "id": 27
        },
        {
            "name": "acral-lentiginous",
            "id": 28
        },
        {
            "name": "amelanotic",
            "id": 29
        },
        {
            "name": "desmoplastic",
            "id": 30
        },
        {
            "name": "b-cell",
            "id": 31
        },
        {
            "name": "t-cell",
            "id": 32
        },
        {
            "name": "nk-cell",
            "id": 33
        },
        {
            "name": "indolent",
            "id": 34
        },
        {
            "name": "aggressive",
            "id": 35
        },
        {
            "name": "primary",
            "id": 36
        },
        {
            "name": "metastatic",
            "id": 37
        },
        {
            "name": "urothelial carcinoma (transitional cell carcinoma)",
            "id": 38
        },
        {
            "name": "squamous cell carcinoma",
            "id": 39
        },
        {
            "name": "exocrine",
            "id": 40
        },
        {
            "name": "neuroendocrine",
            "id": 41
        },
        {
            "name": "n/a",
            "id": 42
        },
        {
            "name": "squamous cell carcinomas",
            "id": 43
        },
        {
            "name": "basal cell carcinoma",
            "id": 44
        },
        {
            "name": "bartholin gland carcinoma",
            "id": 45
        },
        {
            "name": "paget's disease",
            "id": 46
        },
        {
            "name": "epithelial",
            "id": 47
        },
        {
            "name": "primary peritoneal",
            "id": 48
        },
        {
            "name": "fallopian tube",
            "id": 49
        },
        {
            "name": "germ cell",
            "id": 50
        },
        {
            "name": "stromal",
            "id": 51
        },
        {
            "name": "smouldering",
            "id": 52
        },
        {
            "name": "active",
            "id": 53
        },
        {
            "name": "intestinal adenocarcinoma",
            "id": 54
        },
        {
            "name": "diffuse adenocarcinoma",
            "id": 55
        },
        {
            "name": "gastrointestinal stromal tumor",
            "id": 56
        },
        {
            "name": "neuroendocrine tumors",
            "id": 57
        },
        {
            "name": "lymphoma",
            "id": 58
        },
        {
            "name": "secondary",
            "id": 59
        },
        {
            "name": "seminoma",
            "id": 60
        },
        {
            "name": "nonseminomatous",
            "id": 61
        },
        {
            "name": "adenocarcinoma,",
            "id": 62
        },
        {
            "name": "gastrointenstinal stromal tumors",
            "id": 63
        },
        {
            "name": "supraglottis",
            "id": 64
        },
        {
            "name": "glottis",
            "id": 65
        },
        {
            "name": "subglottis",
            "id": 66
        },
        {
            "name": "minor salivary gland",
            "id": 67
        },
        {
            "name": "mucosal melanoma",
            "id": 68
        },
        {
            "name": "anal canal",
            "id": 69
        },
        {
            "name": "perianal skin",
            "id": 70
        },
        {
            "name": "classical",
            "id": 71
        },
        {
            "name": "nodular lymphocyte-predominant",
            "id": 72
        },
        {
            "name": "adenosquamous carcinomas",
            "id": 73
        },
        {
            "name": "carcinosarcoma",
            "id": 74
        },
        {
            "name": "pleural",
            "id": 75
        },
        {
            "name": "peritoneal",
            "id": 76
        },
        {
            "name": "pericardial",
            "id": 77
        },
        {
            "name": "testicular",
            "id": 78
        },
        {
            "name": "intrahepatic",
            "id": 79
        },
        {
            "name": "perihilar",
            "id": 80
        },
        {
            "name": "distal extrahepatic",
            "id": 81
        },
        {
            "name": "multilineage dysplasia",
            "id": 82
        },
        {
            "name": "single lineage dysplasia",
            "id": 83
        },
        {
            "name": "ring sideroblasts",
            "id": 84
        },
        {
            "name": "excess blasts",
            "id": 85
        },
        {
            "name": "isolated del",
            "id": 86
        },
        {
            "name": "epithelial tumor",
            "id": 87
        },
        {
            "name": "adrenal",
            "id": 88
        },
        {
            "name": "carcinoid",
            "id": 89
        },
        {
            "name": "merkel cell",
            "id": 90
        },
        {
            "name": "pancreatic neuroendocrine",
            "id": 91
        },
        {
            "name": "paraganglioma",
            "id": 92
        },
        {
            "name": "pheochromocytoma",
            "id": 93
        },
        {
            "name": "osteosarcoma",
            "id": 94
        },
        {
            "name": "ewing tumor",
            "id": 95
        },
        {
            "name": "chondrosarcoma",
            "id": 96
        },
        {
            "name": "incidentalomas",
            "id": 97
        },
        {
            "name": "aldosteronomas",
            "id": 98
        },
        {
            "name": "adrenal cortex tumors",
            "id": 99
        },
        {
            "name": "pheochromocytomas & paragangliomas",
            "id": 100
        },
        {
            "name": "adrenal cortical carcinoma",
            "id": 101
        },
        {
            "name": "paranasal sinus",
            "id": 102
        },
        {
            "name": "nose cavity",
            "id": 103
        },
        {
            "name": "mucoepidermoid carcinoma",
            "id": 104
        },
        {
            "name": "adenoid cystic carcinoma",
            "id": 105
        },
        {
            "name": "acinic cell carcinoma (acinic cell adenocarcinoma)",
            "id": 106
        },
        {
            "name": "polymorphous adenocarcinoma",
            "id": 107
        },
        {
            "name": "soft tissue",
            "id": 108
        },
        {
            "name": "bone",
            "id": 109
        },
        {
            "name": "neuroectodermal tumor",
            "id": 110
        },
        {
            "name": "primitive neuroectodermal tumor",
            "id": 111
        },
        {
            "name": "carcinoma",
            "id": 112
        },
        {
            "name": "nephroblastoma",
            "id": 113
        }
    ]
};

const symptomFilter = {
    "options": [
        {
            "name": "chest pain",
            "id": 0
        },
        {
            "name": "constant coughing",
            "id": 1
        },
        {
            "name": "coughing up blood",
            "id": 2
        },
        {
            "name": "repeated pneumonia",
            "id": 3
        },
        {
            "name": "shortness of breath",
            "id": 4
        },
        {
            "name": "swollen lymph nodes",
            "id": 5
        },
        {
            "name": "tiredness",
            "id": 6
        },
        {
            "name": "weight loss with unknown cause",
            "id": 7
        },
        {
            "name": "wheezing coughs",
            "id": 8
        },
        {
            "name": "blood in the urine or semen",
            "id": 9
        },
        {
            "name": "difficulty getting or sustaining an erection (impotence)",
            "id": 10
        },
        {
            "name": "frequent pain or stiffness in the lower back, hips or upper thighs",
            "id": 11
        },
        {
            "name": "frequent urination (particularly at night)",
            "id": 12
        },
        {
            "name": "interrupted flow of urine",
            "id": 13
        },
        {
            "name": "pain or burning during urination",
            "id": 14
        },
        {
            "name": "painful ejaculation",
            "id": 15
        },
        {
            "name": "new lump in your breast or armpit",
            "id": 16
        },
        {
            "name": "dimple on the outside of your breast",
            "id": 17
        },
        {
            "name": "change of breast shape",
            "id": 18
        },
        {
            "name": "nipple discharge, including blood",
            "id": 19
        },
        {
            "name": "pain",
            "id": 20
        },
        {
            "name": "red, flaky skin around your nipple",
            "id": 21
        },
        {
            "name": "thickening or swelling of one part of your breast",
            "id": 22
        },
        {
            "name": "abdominal pain and tenderness in the lower abdomen",
            "id": 23
        },
        {
            "name": "blood in the stool or rectal bleeding",
            "id": 24
        },
        {
            "name": "diarrhea, constipation or other changes in bowel habits that last more than a few days",
            "id": 25
        },
        {
            "name": "narrow stools",
            "id": 26
        },
        {
            "name": "weakness and fatigue",
            "id": 27
        },
        {
            "name": "the feeling that a bowel movement has not emptied the bowel completely",
            "id": 28
        },
        {
            "name": "unexplained weight loss",
            "id": 29
        },
        {
            "name": "pelvic mass",
            "id": 30
        },
        {
            "name": "pelvic pain",
            "id": 31
        },
        {
            "name": "unexplained or abnormal vaginal bleeding",
            "id": 32
        },
        {
            "name": "a constant cough",
            "id": 33
        },
        {
            "name": "difficulty swallowing",
            "id": 34
        },
        {
            "name": "pain in the front of the neck that can travel up to the ears",
            "id": 35
        },
        {
            "name": "trouble breathing",
            "id": 36
        },
        {
            "name": "voice changes, such as hoarseness, that don’t go away",
            "id": 37
        },
        {
            "name": "a mass or lump on the side or lower back",
            "id": 38
        },
        {
            "name": "blood in your urine",
            "id": 39
        },
        {
            "name": "fatigue",
            "id": 40
        },
        {
            "name": "loss of appetite",
            "id": 41
        },
        {
            "name": "low back pain",
            "id": 42
        },
        {
            "name": "recurrent fevers",
            "id": 43
        },
        {
            "name": "swelling of your ankles and legs",
            "id": 44
        },
        {
            "name": "weight loss",
            "id": 45
        },
        {
            "name": "a sore that won’t heal",
            "id": 46
        },
        {
            "name": "changes in a mole’s surface, such as scaliness or bleeding",
            "id": 47
        },
        {
            "name": "changes in sensation around a mole, such as new itchiness or tenderness",
            "id": 48
        },
        {
            "name": "moles or skin spots larger than 1/4 inch wide",
            "id": 49
        },
        {
            "name": "moles or skin spots that are asymmetrical, meaning the two halves don’t match",
            "id": 50
        },
        {
            "name": "moles or skin spots that change size, shape or color",
            "id": 51
        },
        {
            "name": "moles or skin spots that look different from other spots on the body",
            "id": 52
        },
        {
            "name": "moles or skin spots with an irregular, ragged or blurred border",
            "id": 53
        },
        {
            "name": "multi-colored skin spots, which may contain different shades of brown, black, pink, red, white or blue",
            "id": 54
        },
        {
            "name": "redness or swelling around a mole or skin spot",
            "id": 55
        },
        {
            "name": "abdominal swelling",
            "id": 56
        },
        {
            "name": "bleeding and bruising",
            "id": 57
        },
        {
            "name": "chills",
            "id": 58
        },
        {
            "name": "coughing",
            "id": 59
        },
        {
            "name": "infections that are unusually frequent or severe",
            "id": 60
        },
        {
            "name": "fever",
            "id": 61
        },
        {
            "name": "feeling of fullness after eating an unusually small amount of food",
            "id": 62
        },
        {
            "name": "night sweats",
            "id": 63
        },
        {
            "name": "swelling in the lymph nodes, which are located in the armpits, groin and neck",
            "id": 64
        },
        {
            "name": "unexpected weight loss",
            "id": 65
        },
        {
            "name": "compromised balance and gait",
            "id": 66
        },
        {
            "name": "changes in behavior or personality",
            "id": 67
        },
        {
            "name": "depression, mood swings or other psychiatric symptoms",
            "id": 68
        },
        {
            "name": "difficulty speaking or other changes in speech",
            "id": 69
        },
        {
            "name": "drowsiness",
            "id": 70
        },
        {
            "name": "headaches that worsen over time",
            "id": 71
        },
        {
            "name": "memory problems or memory loss",
            "id": 72
        },
        {
            "name": "nausea, dizziness or vomiting",
            "id": 73
        },
        {
            "name": "seizures",
            "id": 74
        },
        {
            "name": "vision changes, particularly blurred vision",
            "id": 75
        },
        {
            "name": "blood with a rusty, red or pink hue, or blood clots, which appear as dark particles in your urine",
            "id": 76
        },
        {
            "name": "frequent urination",
            "id": 77
        },
        {
            "name": "needing to urinate but not being able to",
            "id": 78
        },
        {
            "name": "pain during urination",
            "id": 79
        },
        {
            "name": "pain in the lower back on one side",
            "id": 80
        },
        {
            "name": "abnormal bleeding, including irregular, heavy or longer periods",
            "id": 81
        },
        {
            "name": "pain in and around the pelvis, with or without sex",
            "id": 82
        },
        {
            "name": "unusual vaginal discharge",
            "id": 83
        },
        {
            "name": "appetite loss",
            "id": 84
        },
        {
            "name": "back or abdominal pain",
            "id": 85
        },
        {
            "name": "blood clots, which often occur in large leg veins",
            "id": 86
        },
        {
            "name": "changes in blood sugar levels that can result in diabetes",
            "id": 87
        },
        {
            "name": "jaundice, which causes eyes and skin to turn a yellowish color, urine to be darker than normal, skin to itch and stool to be greasy or light in color",
            "id": 88
        },
        {
            "name": "nausea and vomiting",
            "id": 89
        },
        {
            "name": "achy bones or joints",
            "id": 90
        },
        {
            "name": "bleeding gums",
            "id": 91
        },
        {
            "name": "dizziness",
            "id": 92
        },
        {
            "name": "enlarged or tender lymph nodes",
            "id": 93
        },
        {
            "name": "excess fatigue",
            "id": 94
        },
        {
            "name": "frequent infections",
            "id": 95
        },
        {
            "name": "frequent nosebleeds",
            "id": 96
        },
        {
            "name": "menstrual periods that are heavier than normal or more frequent",
            "id": 97
        },
        {
            "name": "pale skin",
            "id": 98
        },
        {
            "name": "prolonged bleeding from minor cuts and scrapes",
            "id": 99
        },
        {
            "name": "shortness of breath or difficulty breathing",
            "id": 100
        },
        {
            "name": "unexplainable weight loss or a change in appetite",
            "id": 101
        },
        {
            "name": "wheezing",
            "id": 102
        },
        {
            "name": "appearance of a mole-like area",
            "id": 103
        },
        {
            "name": "burning or pain",
            "id": 104
        },
        {
            "name": "changes in appearance in an area",
            "id": 105
        },
        {
            "name": "development of a pink, red or white lump",
            "id": 106
        },
        {
            "name": "irregular bleeding or discharge",
            "id": 107
        },
        {
            "name": "itching",
            "id": 108
        },
        {
            "name": "open sore",
            "id": 109
        },
        {
            "name": "red, scaly area",
            "id": 110
        },
        {
            "name": "skin thickening",
            "id": 111
        },
        {
            "name": "back pain",
            "id": 112
        },
        {
            "name": "bloating and/or abdominal swelling",
            "id": 113
        },
        {
            "name": "changes in frequency or urgency of urination",
            "id": 114
        },
        {
            "name": "constipation",
            "id": 115
        },
        {
            "name": "feelings of stomach fullness",
            "id": 116
        },
        {
            "name": "menstrual changes, including heavy or irregular periods",
            "id": 117
        },
        {
            "name": "pain during intercourse",
            "id": 118
        },
        {
            "name": "pain in the abdomen or pelvis",
            "id": 119
        },
        {
            "name": "upset stomach",
            "id": 120
        },
        {
            "name": "feeling short of breath",
            "id": 121
        },
        {
            "name": "frequent infections of the sinuses, urinary tract or elsewhere, with no obvious cause",
            "id": 122
        },
        {
            "name": "kidney abnormalities",
            "id": 123
        },
        {
            "name": "ongoing tiredness that may worsen over time",
            "id": 124
        },
        {
            "name": "persistent or frequent pain in the back or other bones ",
            "id": 125
        },
        {
            "name": "swelling of the hands, feet or other extremities",
            "id": 126
        },
        {
            "name": "abdominal pain or discomfort",
            "id": 127
        },
        {
            "name": "bloody stool",
            "id": 128
        },
        {
            "name": "feeling full after eating a small amount",
            "id": 129
        },
        {
            "name": "indigestion or heartburn",
            "id": 130
        },
        {
            "name": "nausea",
            "id": 131
        },
        {
            "name": "swelling of the abdomen",
            "id": 132
        },
        {
            "name": "vomiting (may be bloody)",
            "id": 133
        },
        {
            "name": "weakness or tiredness (caused by anemia)",
            "id": 134
        },
        {
            "name": "yellow eyes or skin (caused if the cancer spreads to the liver)",
            "id": 135
        },
        {
            "name": "abdominal pain in the belly or near the right shoulder blade",
            "id": 136
        },
        {
            "name": "decreased appetite",
            "id": 137
        },
        {
            "name": "enlarged liver or a “fullness” under the right ribs",
            "id": 138
        },
        {
            "name": "enlarged spleen with the same “fullness” feeling",
            "id": 139
        },
        {
            "name": "feeling very full after a small meal",
            "id": 140
        },
        {
            "name": "fluid build-up in the abdomen",
            "id": 141
        },
        {
            "name": "nausea or vomiting",
            "id": 142
        },
        {
            "name": "unintentional weight loss",
            "id": 143
        },
        {
            "name": "yellowing of the skin and eyes, also called jaundice",
            "id": 144
        },
        {
            "name": "red or white patches on the mouth, tongue or gums",
            "id": 145
        },
        {
            "name": "sore or lump on the mouth or lips",
            "id": 146
        },
        {
            "name": "bleeding or pain in the mouth or the gums",
            "id": 147
        },
        {
            "name": "breast tenderness or growth of breast tissue (gynecomastia)",
            "id": 148
        },
        {
            "name": "dull pain or aching in the groin/lower abdomen",
            "id": 149
        },
        {
            "name": "fluid build-up in the scrotum",
            "id": 150
        },
        {
            "name": "heavy feeling in the scrotum",
            "id": 151
        },
        {
            "name": "pain or discomfort in the scrotum or a testicle",
            "id": 152
        },
        {
            "name": "swelling or a lump in either testicle",
            "id": 153
        },
        {
            "name": "chronic cough",
            "id": 154
        },
        {
            "name": "hoarseness",
            "id": 155
        },
        {
            "name": "indigestion",
            "id": 156
        },
        {
            "name": "vomiting",
            "id": 157
        },
        {
            "name": "chronic pain in the right lower abdomen",
            "id": 158
        },
        {
            "name": "diarrhea",
            "id": 159
        },
        {
            "name": "fullness or bloating",
            "id": 160
        },
        {
            "name": "pelvic or lower abdominal pain",
            "id": 161
        },
        {
            "name": "a lump in the neck",
            "id": 162
        },
        {
            "name": "coughing or spitting up blood",
            "id": 163
        },
        {
            "name": "difficulty breathing",
            "id": 164
        },
        {
            "name": "ear pain",
            "id": 165
        },
        {
            "name": "hoarseness or voice changes",
            "id": 166
        },
        {
            "name": "pain when swallowing",
            "id": 167
        },
        {
            "name": "persistent sore throat or cough",
            "id": 168
        },
        {
            "name": "anal or rectal bleeding",
            "id": 169
        },
        {
            "name": "discharge from the anus",
            "id": 170
        },
        {
            "name": "itching in or around the anus",
            "id": 171
        },
        {
            "name": "lump in the anal opening",
            "id": 172
        },
        {
            "name": "more or fewer bowel movements than usual",
            "id": 173
        },
        {
            "name": "narrowed stools",
            "id": 174
        },
        {
            "name": "pain in or around the anus",
            "id": 175
        },
        {
            "name": "straining during bowel movements",
            "id": 176
        },
        {
            "name": "swollen lymph glands around the anus or groin",
            "id": 177
        },
        {
            "name": "abdominal pain, especially in the upper, right side",
            "id": 178
        },
        {
            "name": "jaundice (yellow tint to the skin)",
            "id": 179
        },
        {
            "name": "lump on the right side of the abdomen",
            "id": 180
        },
        {
            "name": "blurred vision",
            "id": 181
        },
        {
            "name": "double vision (diplopia)",
            "id": 182
        },
        {
            "name": "irritation",
            "id": 183
        },
        {
            "name": "a perception of flashes of light in the eye (photopsia)",
            "id": 184
        },
        {
            "name": "a reduction in the total field of vision",
            "id": 185
        },
        {
            "name": "loss of vision",
            "id": 186
        },
        {
            "name": "a sensation of a foreign body in the field of vision (floaters)",
            "id": 187
        },
        {
            "name": "redness, bulging or displacement of the eye (proptosis)",
            "id": 188
        },
        {
            "name": "a change in the shape of the pupil",
            "id": 189
        },
        {
            "name": "pressure within the eye",
            "id": 190
        },
        {
            "name": "metamorphopsia (a distortion of vision where, when a person looks at a grid of straight lines, the lines appear wavy and parts of the grid appears blank).",
            "id": 191
        },
        {
            "name": "abdominal lumps, pain, or swelling",
            "id": 192
        },
        {
            "name": "abnormal heart rhythm",
            "id": 193
        },
        {
            "name": "anemia (low blood count)",
            "id": 194
        },
        {
            "name": "breathing difficulty",
            "id": 195
        },
        {
            "name": "low blood pressure",
            "id": 196
        },
        {
            "name": "pain focused under the rib cage",
            "id": 197
        },
        {
            "name": "swallowing difficulties",
            "id": 198
        },
        {
            "name": "unexplainable weight loss",
            "id": 199
        },
        {
            "name": "weakness",
            "id": 200
        },
        {
            "name": "an area of skin at the tip of the penis becoming thicker or changing color",
            "id": 201
        },
        {
            "name": "a lump on the penis",
            "id": 202
        },
        {
            "name": "a reddish, velvety rash",
            "id": 203
        },
        {
            "name": "a sore, which could bleed",
            "id": 204
        },
        {
            "name": "flat, bluish-brown growths",
            "id": 205
        },
        {
            "name": "lumps in the groin area",
            "id": 206
        },
        {
            "name": "small, crusty bumps",
            "id": 207
        },
        {
            "name": "smelly discharge",
            "id": 208
        },
        {
            "name": "swelling, making it hard to draw back the foreskin",
            "id": 209
        },
        {
            "name": "bruising or bleeding easily",
            "id": 210
        },
        {
            "name": "cough",
            "id": 211
        },
        {
            "name": "muscle weakness",
            "id": 212
        },
        {
            "name": "arm or leg swelling",
            "id": 213
        },
        {
            "name": "reddish rash on the face, neck, or upper chest (dermatomyositis)",
            "id": 214
        },
        {
            "name": "myasthenia gravis (associated autoimmune disorder)",
            "id": 215
        },
        {
            "name": "pain ",
            "id": 216
        },
        {
            "name": "unexpected weight loss ",
            "id": 217
        },
        {
            "name": "numbness in the mouth",
            "id": 218
        },
        {
            "name": "pain in the ear",
            "id": 219
        },
        {
            "name": "persistent sore throat",
            "id": 220
        },
        {
            "name": "red or white patch on the tongue that won’t heal",
            "id": 221
        },
        {
            "name": "sore spot or lump on the tongue that won’t heal",
            "id": 222
        },
        {
            "name": "unexplained bleeding on the tongue that’s not caused by any injury",
            "id": 223
        },
        {
            "name": "discharge from the ear",
            "id": 224
        },
        {
            "name": "facial weakness",
            "id": 225
        },
        {
            "name": "headache",
            "id": 226
        },
        {
            "name": "hearing loss",
            "id": 227
        },
        {
            "name": "lump in or near the opening of the ear canal",
            "id": 228
        },
        {
            "name": "flat, firm, pale or yellow areas",
            "id": 229
        },
        {
            "name": "open sores",
            "id": 230
        },
        {
            "name": "pink growths with raised edges and abnormal blood vessels",
            "id": 231
        },
        {
            "name": "raised reddish patches, which are often itchy",
            "id": 232
        },
        {
            "name": "small pink or red shiny bumps, which might have darker areas",
            "id": 233
        },
        {
            "name": "bone fractures",
            "id": 234
        },
        {
            "name": "a lump or swelling",
            "id": 235
        },
        {
            "name": "numbness or tingling",
            "id": 236
        },
        {
            "name": "pain in the area of the tumor",
            "id": 237
        },
        {
            "name": "bruising easily",
            "id": 238
        },
        {
            "name": "depression or other unusual changes in mood",
            "id": 239
        },
        {
            "name": "early puberty in younger females",
            "id": 240
        },
        {
            "name": "enlarged penis or clitoris",
            "id": 241
        },
        {
            "name": "excessive facial or body hair",
            "id": 242
        },
        {
            "name": "fat deposits behind the neck and shoulders",
            "id": 243
        },
        {
            "name": "high blood pressure",
            "id": 244
        },
        {
            "name": "high blood sugar",
            "id": 245
        },
        {
            "name": "impotence, enlarged or tender breasts or reduced sex drive in men",
            "id": 246
        },
        {
            "name": "irregular periods, receding hairline or deepening of the voice in women",
            "id": 247
        },
        {
            "name": "low potassium",
            "id": 248
        },
        {
            "name": "muscle cramps",
            "id": 249
        },
        {
            "name": "osteoporosis",
            "id": 250
        },
        {
            "name": "pink or purple stretch marks on the abdomen",
            "id": 251
        },
        {
            "name": "weight gain, particularly in the abdomen, cheeks and above the collar bone",
            "id": 252
        },
        {
            "name": "decreased sense of smell",
            "id": 253
        },
        {
            "name": "nosebleeds",
            "id": 254
        },
        {
            "name": "runny nose or mucus draining into your throat",
            "id": 255
        },
        {
            "name": "sinus pain or pressure",
            "id": 256
        },
        {
            "name": "stuffy nose, usually on one side",
            "id": 257
        },
        {
            "name": "a lump or swelling on the cheek, under the chin, under the tongue, at the jawline or on the neck",
            "id": 258
        },
        {
            "name": "difficulty opening the mouth",
            "id": 259
        },
        {
            "name": "facial asymmetry, where the face is not the same on both sides",
            "id": 260
        },
        {
            "name": "fluid draining from the ear",
            "id": 261
        },
        {
            "name": "numbness or weakness in the face",
            "id": 262
        },
        {
            "name": "persistent pain in the cheek, jaw, mouth, neck or ear",
            "id": 263
        },
        {
            "name": "abdominal pain that gets worse over time",
            "id": 264
        },
        {
            "name": "a new lump that gets larger over time",
            "id": 265
        },
        {
            "name": "black, sticky bowel movements",
            "id": 266
        },
        {
            "name": "blood in your stool or vomit",
            "id": 267
        },
        {
            "name": "persistent back pain, often in the lower back or sacrum",
            "id": 268
        },
        {
            "name": "pain that may radiate to other areas, such as the hips, legs, or arms",
            "id": 269
        },
        {
            "name": "numbness, tingling, or weakness in the affected area",
            "id": 270
        },
        {
            "name": "difficulty walking or changes in gait",
            "id": 271
        },
        {
            "name": "bowel or bladder dysfunction",
            "id": 272
        },
        {
            "name": "difficulty swallowing or breathing if the tumor compresses nearby structures in the head and neck region",
            "id": 273
        },
        {
            "name": "vision changes or double vision if the tumor affects the cranial nerves",
            "id": 274
        },
        {
            "name": "swelling or mass at the site of the tumor, which may be palpable",
            "id": 275
        },
        {
            "name": "loss of appetite and unintentional weight loss",
            "id": 276
        },
        {
            "name": "back or thigh pain",
            "id": 277
        },
        {
            "name": "sciatica",
            "id": 278
        },
        {
            "name": "bladder symptoms",
            "id": 279
        },
        {
            "name": "unilateral edema",
            "id": 280
        },
        {
            "name": "pain or discomfort in the affected area",
            "id": 281
        },
        {
            "name": "swelling or a palpable mass that may be felt under the skin",
            "id": 282
        },
        {
            "name": "fatigue or weakness",
            "id": 283
        },
        {
            "name": "abdominal pain or discomfort if the tumor arises in the abdomen",
            "id": 284
        },
        {
            "name": "bowel or urinary changes if the tumor compresses nearby organs",
            "id": 285
        },
        {
            "name": "vaginal bleeding or discharge if the tumor arises in the uterus or other reproductive organs",
            "id": 286
        },
        {
            "name": "respiratory symptoms such as coughing, chest pain, or difficulty breathing if the tumor affects the lungs or airways",
            "id": 287
        },
        {
            "name": "bone pain if the tumor arises in the bones",
            "id": 288
        },
        {
            "name": "swelling or a lump that may be painless or tender to the touch, especially in the arms, legs, head, neck, or other soft tissue areas",
            "id": 289
        },
        {
            "name": "difficulty urinating or having bowel movements if the tumor affects the pelvic area",
            "id": 290
        },
        {
            "name": "changes in bowel or bladder habits",
            "id": 291
        },
        {
            "name": "bleeding from the nose, throat, or other areas if the tumor arises in the head and neck region",
            "id": 292
        },
        {
            "name": "problems with vision or hearing if the tumor affects the eye or ear",
            "id": 293
        },
        {
            "name": "headaches or dizziness if the tumor affects the brain",
            "id": 294
        },
        {
            "name": "bone pain or fractures if the tumor affects the bones",
            "id": 295
        },
        {
            "name": "shortness of breath or coughing if the tumor affects the lungs",
            "id": 296
        },
        {
            "name": "bone pain",
            "id": 297
        },
        {
            "name": "lumps",
            "id": 298
        },
        {
            "name": "headaches",
            "id": 299
        },
        {
            "name": "clumsiness",
            "id": 300
        },
        {
            "name": "change in vision",
            "id": 301
        },
        {
            "name": "handwriting problems",
            "id": 302
        },
        {
            "name": "kidney stones",
            "id": 303
        },
        {
            "name": "increased thirst",
            "id": 304
        },
        {
            "name": "bone pain and fractures",
            "id": 305
        },
        {
            "name": "abdominal pain",
            "id": 306
        },
        {
            "name": "depression or mood changes",
            "id": 307
        },
        {
            "name": "memory problems",
            "id": 308
        },
        {
            "name": "joint pain",
            "id": 309
        },
        {
            "name": "a painless, palpable abdominal mass",
            "id": 310
        },
        {
            "name": "blood in the urine (in about 20% of cases)",
            "id": 311
        },
        {
            "name": "high blood pressure in some cases (especially if synchronous or metachronous bilateral kidney involvement)",
            "id": 312
        },
        {
            "name": "rarely as varicocele",
            "id": 313
        }
    ]
};

const bodyFilter = {
    "options": [
        {
            "name": "lungs",
            "id": 0
        },
        {
            "name": "prostate",
            "id": 1
        },
        {
            "name": "breasts",
            "id": 2
        },
        {
            "name": "colon",
            "id": 3
        },
        {
            "name": "rectum",
            "id": 4
        },
        {
            "name": "uterus",
            "id": 5
        },
        {
            "name": "thyroid",
            "id": 6
        },
        {
            "name": "kidney",
            "id": 7
        },
        {
            "name": "skin",
            "id": 8
        },
        {
            "name": "lymph nodes",
            "id": 9
        },
        {
            "name": "spleen",
            "id": 10
        },
        {
            "name": "bone marrow",
            "id": 11
        },
        {
            "name": "thymus",
            "id": 12
        },
        {
            "name": "adenoids and tonsils",
            "id": 13
        },
        {
            "name": "digestive track",
            "id": 14
        },
        {
            "name": "brain",
            "id": 15
        },
        {
            "name": "bladder",
            "id": 16
        },
        {
            "name": "cervix",
            "id": 17
        },
        {
            "name": "pancreas",
            "id": 18
        },
        {
            "name": "white blood cells",
            "id": 19
        },
        {
            "name": "vulva",
            "id": 20
        },
        {
            "name": "ovaries",
            "id": 21
        },
        {
            "name": "plasma cells",
            "id": 22
        },
        {
            "name": "stomach",
            "id": 23
        },
        {
            "name": "liver",
            "id": 24
        },
        {
            "name": "oropharynx",
            "id": 25
        },
        {
            "name": "throat",
            "id": 26
        },
        {
            "name": "testicles",
            "id": 27
        },
        {
            "name": "esophagus",
            "id": 28
        },
        {
            "name": "small intenstine",
            "id": 29
        },
        {
            "name": "larynx",
            "id": 30
        },
        {
            "name": "mouth",
            "id": 31
        },
        {
            "name": "tongue",
            "id": 32
        },
        {
            "name": "gums",
            "id": 33
        },
        {
            "name": "anal canal",
            "id": 34
        },
        {
            "name": "anus",
            "id": 35
        },
        {
            "name": "gallbladder",
            "id": 36
        },
        {
            "name": "eye",
            "id": 37
        },
        {
            "name": "uvea",
            "id": 38
        },
        {
            "name": "orbit",
            "id": 39
        },
        {
            "name": "mesothelium",
            "id": 40
        },
        {
            "name": "heart",
            "id": 41
        },
        {
            "name": "bile ducts",
            "id": 42
        },
        {
            "name": "penis",
            "id": 43
        },
        {
            "name": "blood cells",
            "id": 44
        },
        {
            "name": "thymus gland",
            "id": 45
        },
        {
            "name": "mediastinum",
            "id": 46
        },
        {
            "name": "large blood vessels",
            "id": 47
        },
        {
            "name": "neuroendocrine cells",
            "id": 48
        },
        {
            "name": "ceruminous glands",
            "id": 49
        },
        {
            "name": "ear",
            "id": 50
        },
        {
            "name": "bone",
            "id": 51
        },
        {
            "name": "adrenal glands",
            "id": 52
        },
        {
            "name": "paranasal sinus",
            "id": 53
        },
        {
            "name": "nasal cavity",
            "id": 54
        },
        {
            "name": "nose",
            "id": 55
        },
        {
            "name": "salivary gland",
            "id": 56
        },
        {
            "name": "bones",
            "id": 57
        },
        {
            "name": "soft tissues",
            "id": 58
        },
        {
            "name": "spine",
            "id": 59
        },
        {
            "name": "skull base",
            "id": 60
        },
        {
            "name": "vertebrae",
            "id": 61
        },
        {
            "name": "sacrum",
            "id": 62
        },
        {
            "name": "coccyx",
            "id": 63
        },
        {
            "name": "joints",
            "id": 64
        },
        {
            "name": "cartilage",
            "id": 65
        },
        {
            "name": "skull",
            "id": 66
        },
        {
            "name": "shoulder and hip joints",
            "id": 67
        },
        {
            "name": "pelvis",
            "id": 68
        },
        {
            "name": "ribs",
            "id": 69
        },
        {
            "name": "gastrointestinal tract",
            "id": 70
        },
        {
            "name": "blood vessels",
            "id": 71
        },
        {
            "name": "retroperitoneum",
            "id": 72
        },
        {
            "name": "head",
            "id": 73
        },
        {
            "name": "neck",
            "id": 74
        },
        {
            "name": "genitourinary tract",
            "id": 75
        },
        {
            "name": "trunk",
            "id": 76
        },
        {
            "name": "abdomen",
            "id": 77
        },
        {
            "name": "chest",
            "id": 78
        },
        {
            "name": "cerebellum",
            "id": 79
        },
        {
            "name": "central nervous system",
            "id": 80
        },
        {
            "name": "posterior fossa",
            "id": 81
        },
        {
            "name": "spinal cord",
            "id": 82
        },
        {
            "name": "parathyroid glands",
            "id": 83
        },
        {
            "name": "kidneys",
            "id": 84
        },
        {
            "name": "nervous system",
            "id": 85
        },
        {
            "name": "muscular system",
            "id": 86
        },
        {
            "name": "cardiovascular system",
            "id": 87
        }
    ]
};

const specialtyFilter = {
    "options": [
        {
            "name": "oncology",
            "id": 0
        },
        {
            "name": "pulmonology",
            "id": 1
        },
        {
            "name": "urology",
            "id": 2
        },
        {
            "name": "gastroenterology",
            "id": 3
        },
        {
            "name": "general surgery",
            "id": 4
        },
        {
            "name": "gynecology",
            "id": 5
        },
        {
            "name": "nephrology",
            "id": 6
        },
        {
            "name": "dermatology",
            "id": 7
        },
        {
            "name": "hematology",
            "id": 8
        },
        {
            "name": "neurosurgery",
            "id": 9
        },
        {
            "name": "neuro-oncology",
            "id": 10
        },
        {
            "name": "hepatology",
            "id": 11
        },
        {
            "name": "haematology",
            "id": 12
        },
        {
            "name": "head and neck surgery",
            "id": 13
        },
        {
            "name": "oral and maxillofacial surgery",
            "id": 14
        },
        {
            "name": "ent surgery",
            "id": 15
        },
        {
            "name": "cardiothoracic surgery",
            "id": 16
        },
        {
            "name": "endocrine oncology",
            "id": 17
        },
        {
            "name": "orthopedics",
            "id": 18
        },
        {
            "name": "oral and maxillofacial pathology",
            "id": 19
        }
    ]
};

export {classificationFilter, symptomFilter, bodyFilter, specialtyFilter};