import React from "react";
import { Card } from "react-bootstrap";

function Visualizations() {
    document.title = "Visualizations";
    return (
        <div>
            <h1 className="text-center">Visualizations</h1>
            <iframe
                width="70%"
                height="1500"
                frameBorder="0"
                style={{ margin: "auto", display: "block" }}
                src="https://observablehq.com/embed/3c71235f80762639@1793?cells=map%2Cchart%2CPieChart"
            ></iframe>
            <h1 className="text-center mt-5 mb-2">Critiques</h1>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What did we do well?</Card.Title>
                    <Card.Text>
                        One of the things we did well was communicate. Whenever an issue, like a bug, popped
                        up, it was quickly mentioned in the chat, and our team responded relatively soon
                        after. In addition, we communicated about group meetings well and in advance through
                        the use of Slack and when2meets. Another area we did well in was collaboration.
                        <br />
                        Whenever we met up whether it was in person or through Zoom, we worked efficiently and
                        well with one another. This enabled us to make good progress during our meetings with
                        planning and actual implementation. Even outside of meetings, we collaborated well
                        with one another, reaching out and working together whenever needed.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What did we learn?</Card.Title>
                    <Card.Text>
                        Individually, we each learned new technical skills, both for front end and back end.
                        More specifically, for the front end, we learned how to use Bootstrap with React, how
                        to connect it with the backend, and how to use a variety of other libraries that
                        enhanced our overall user interface. For the back end, we became more familiar with
                        working with the database, building our own API, and creating unit tests for those
                        endpoints.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What did we teach each other?</Card.Title>
                    <Card.Text>
                        Although we learned some of the technical skills individually, some of the skills we
                        picked up came from each other. This usually happened when we were pair programming or
                        when we needed help on a bug. We also learned leadership through this experience. By
                        having a different person lead each phase, we were able to learn from the leadership
                        of others and use that knowledge to better lead the upcoming phases.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What can we do better?</Card.Title>
                    <Card.Text>
                        One thing we can improve on is time management. Although we worked well with one
                        another, we typically started the project late and as a result, we were rushing
                        towards the end and oftentimes, had to use a couple of slip days to get our project
                        in. By managing our time better, we can start and finish earlier and not have to
                        stress a lot near the deadline.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What effect did the peer reviews have?</Card.Title>
                    <Card.Text>
                        The peer reviews gave each of us valuable and actionable feedback that we could
                        implement in the next phase. It showed us areas of weakness that we may not have been
                        aware of as well as areas that we did good in. Understanding our strengths and
                        weaknesses was vital for our constant improvement, both personal and team wise.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What puzzles us?</Card.Title>
                    <Card.Text>
                        When setting up the backend hosting we came across a lot of terms like elastic load
                        balancer which was something we hadn’t heard of before, it would be nice to learn what
                        they are and how they work. Another thing we would want to learn about is (better)
                        server side rendering, for our app we did most of the rendering on the client side
                        using HTTP requests and dynamic image loading, but learning how to use SSR with React
                        and Flask would be better for user experience.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}
export default Visualizations;
