/* eslint-disable react/prop-types */
import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import useImage from "../../utils/useImage";

/**
 * Makes a simple Card for Types in a "Relevant Types" category on
 * an instance page.
 * @param {Object} param 
 * @returns 
 */
const TypeCard = ({ type, rate }) => {
    const { image } = useImage(type.image);

    /**
     * Simple check to make a groupitem for ratings.
     * @returns a ListGroupItem if a valid rating is passed, empty p otherwise.
     */
    function rateComponent() {
        if (rate > 0) {
            return <ListGroupItem>Rate: {rate.toLocaleString()}</ListGroupItem>;
        }
        return <p/>
    }

    return (
        <Card className="text-center p-2 mt-2 mb-2 ms-auto me-auto oncologistCard">
            <Card.Img src={image} className="innerInstanceCardImage"/>
            <Card.Body>
                <Card.Header>
                    <a href={"../../type/" + type.name}>
                        {type.name}
                    </a>
                </Card.Header>
                <ListGroup className="text-start">
                    {rateComponent()}
                </ListGroup>
            </Card.Body>
        </Card>
    );
};

export default TypeCard;
