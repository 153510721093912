/* eslint-disable react/prop-types */
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import useImage from "../utils/useImage";
import HighlightText from "../utils/highlight";

const OncologistsCard = ({ oncologist, query }) => {
    const link = "../oncologists/" + oncologist.id;
    var instance = oncologist;
    var rating = instance.rating;
    var specialties = "";
    if (instance.conditions.length > 0) {
        for (let i = 0; i < instance.conditions.length - 1; i++) {
            specialties += instance.conditions[i].type + ", ";
        }
        specialties += instance.conditions[instance.conditions.length - 1].type;
    }
    var name = instance.name;
    var clinic = instance.location.name;
    var address = instance.location.address;
    var education = instance.background.degree;
    var imageAddr = instance.image;
    const { image } = useImage(imageAddr);
    return (
        <Card className="modelCard">
            <Card.Img
                variant="top"
                src={image}
                height={"auto"}
                style={{ objectFit: "stretch", aspectRatio: "1" }}
                className="instanceCardImage"
            />
            <Card.Body>
                <Card.Title><HighlightText content={name} query={query}/></Card.Title>
                <Card.Text>
                    <strong>Rating: </strong>
                    <HighlightText content={rating} query={query}/>
                    <br />
                    <strong>Specialites: </strong>
                    <HighlightText content={specialties} query={query}/>
                    <br />
                    <strong>Clinic: </strong>
                    <HighlightText content={clinic} query={query}/>
                    <br />
                    <strong>Address: </strong>
                    <HighlightText content={address} query={query}/>
                    <br />
                    <strong>Education: </strong>
                    <HighlightText content={education} query={query}/>
                </Card.Text>
                <Button href={link}>More Info</Button>
            </Card.Body>
        </Card>
    );
};

export default OncologistsCard;
