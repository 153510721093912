import React from "react";
import { Card } from "react-bootstrap";

function Visualizations() {
    document.title = "Provider Visualizations";
    return (
        <div>
            <h1 className="text-center">Provider Visualizations</h1>
            <iframe
                width="70%"
                height="1500"
                frameBorder="0"
                style={{ margin: "auto", display: "block" }}
                src="https://observablehq.com/embed/f5d61fc09d5742e9@2075?cells=viewof+layout%2Cchart1%2CPieChart"
            ></iframe>
            <h1 className="text-center mt-5 mb-2">Critiques</h1>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What did they do well?</Card.Title>
                    <Card.Text>
                    We liked how straightforward their website was. There was little confusion about 
                    where to go for certain information and how to search/sort/filter the results. The 
                    minimalist color scheme they had for the website was also quite nice since it didn’t 
                    overload the user with colors.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>How effective was their RESTful API?</Card.Title>
                    <Card.Text>
                    It seems to be working well with good speeds so that part is quite nice, however 
                    the Postman documentation is outdated and incorrect (or we were given an outdated 
                    Postman URL) since it uses http instead of https, and doesn’t include important 
                    details like limit and skip search parameters for queries. However, the API does 
                    actually use https, it does use the parameters properly, and the JSON it returns is nicely 
                    formatted so it was an effective RESTful API. There also aren’t any Cross Origin Resource 
                    Sharing issues we encountered which is a good sign.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>How well did they implement your user stories?</Card.Title>
                    <Card.Text>
                    Overall, they did a good job implementing our user stories. For the ones that they decided 
                    against implementing due to time constraints or other issues, they documented it well in 
                    their technical report and communicated it with us through the story comment section. As 
                    for the doable ones, they went in and implemented those changes whether it was during the 
                    current phase or a little later.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What did we learn from their website?</Card.Title>
                    <Card.Text>
                    One of the things that we learned from their website were all of the resources that 
                    were available in New York for the homeless population. It was interesting to see 
                    the similarities but also the differences in the resources and amount of resources 
                    available for specific boroughs, which show potential areas of change that could 
                    be implemented for New York. 
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What can they do better?</Card.Title>
                    <Card.Text>
                    There are several small areas where they could improve. One area is font size. 
                    Some of the text on the website, such as the filters and instance pages, are still 
                    a little small. Increasing the size can make the website a lot easier to read for 
                    all. The last area is overall spacing. For instance, the text on the home page is a 
                    little off center, which if fixed, would make it look better and more uniform. There 
                    are also load time issues for some of the pages like the About page where the images 
                    visibly slowly load in, changing the structure of the website.
                    </Card.Text>
                </Card.Body>
            </Card>
            <Card style={{ width: "75%", display: "block", margin: "auto" }} className="mb-2">
                <Card.Body>
                    <Card.Title>What puzzles us about their website</Card.Title>
                    <Card.Text>
                    One thing that we were left wondering about their website is if there was a correlation 
                    between the amount of resources available in certain regions and the homeless population 
                    there. We were also curious about whether it would be possible for a page like the 
                    homeless shelter to link to the closest food pantries to that shelter rather than just 
                    pantries in that region.
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    );
}
export default Visualizations;
