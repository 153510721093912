import CountiesCard from "../components/CountiesCard";
import { Row, Col, Button, FloatingLabel, Form } from "react-bootstrap";
import requestPrefix from "../utils/requestPrefix";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import pageTitles from "../utils/pageTitles";
import SortButton from "../components/SortButton";

function Counties() {
    document.title = "Counties";
    const [currentPage, setCurrentPage] = useState(1);
    const [countyData, setCountyData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const cardsPerPage = 9;
    const cancerFilter = {
        options: pageTitles.map((x) => {
            return { name: x.county, id: x.county };
        })
    };
    const SORT_BUTTONS = {
        POPULATION: 0,
        ANNUAL: 1,
        CRUDE_RATE: 2,
        AGE_RATE: 3
    };
    let [cancerFilterSelected, setCancerFilterSelected] = useState([]);
    let lastSortedClick = -1;

    useEffect(() => {
        const fetchCountyData = async () => {
            if (countyData) {
                return;
            }
            try {
                const response = await axios.get(`${requestPrefix}/counties`);
                setCountyData(response.data);
            } catch (error) {
                console.error("Error fetching county data:", error);
            }
        };
        fetchCountyData();
    }, []);

    if (countyData == null) {
        return <>Still Loading...</>;
    }

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = countyData.slice(indexOfFirstCard, indexOfLastCard);
    const totalPages = Math.ceil(countyData.length / cardsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleFirstPage = () => {
        setCurrentPage(1)
    };
    
    const handleLastPage = () => {
        setCurrentPage(totalPages)
    };

    function onSelect(selectedList) {
        setCancerFilterSelected(selectedList);
    }

    // async function makeSearch() {
    //     if (!searchQuery) {
    //         // If searchQuery is empty, set oncologistData to its initial state
    //         setCurrentPage(1);
    //         setCountyData(countyData);
    //         return;
    //     }
    //     const body = {
    //         searchQuery: searchQuery
    //     };
    //     const response = await axios.post(`${requestPrefix}/counties/searchbar`, body, {
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });
    //     if (response.status == 200){
    //         setCurrentPage(1);
    //         setCountyData(response.data);
    //     }
    // }

    async function makeFetch() {
        const cancerNames = [];
        for (let cancerType of cancerFilterSelected) {
            cancerNames.push(cancerType.id);
        }
        let sortParams = {};
        switch(lastSortedClick) {
            case SORT_BUTTONS.POPULATION:
                sortParams.population = document.getElementById("populationButton").getAttribute("direction");
                break;
            case SORT_BUTTONS.ANNUAL:
                sortParams.annualCases = document.getElementById("annualCasesButton").getAttribute("direction");
                break;
            case SORT_BUTTONS.CRUDE_RATE:
                sortParams.crudeRate = document.getElementById("crudeRateButton").getAttribute("direction");
                break;
            case SORT_BUTTONS.AGE_RATE:
                sortParams.ageRate = document.getElementById("ageRateButton").getAttribute("direction");
                break;
        }
        const body = {
            searchQuery: searchQuery,
            filterParams: {
                population: Number(document.getElementById("populationFilter").value),
                numCases: Number(document.getElementById("numCasesFilter").value),
                crudeRate: Number(document.getElementById("crudeRateFilter").value),
                ageRate: Number(document.getElementById("ageRateFilter").value),
                cancer: cancerNames
            },
            sortParams: sortParams
        };
        const response = await axios.post(`${requestPrefix}/counties/search`, body, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status == 200) {
            setCurrentPage(1);
            setCountyData(response.data);
        }
    }

    function resetOtherButtons(buttonIndex) {
        lastSortedClick = buttonIndex;
    }

    return (
        <div className="app">
            <h1 className="text-center">Counties in Kentucky</h1>

            <Row className="justify-content-center align-items-center" style={{ width: "88.25vw", margin: "auto" }}>
                <Col>
                    <FloatingLabel
                        label="Search"
                        controlId="search"
                        style={{ width: "42.25vw", margin: "auto" }}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <Button onClick={makeFetch}>Search</Button>
                </Col>
            </Row>
            <Row
                xs={1}
                sm={2}
                md={4}
                lg={5}
                style={{ padding: "1vw", width: "90vw", margin: "auto"}}
                className="justify-content-center align-items-center"
            >
                <Col className="filterInput">
                    <Multiselect
                        options={cancerFilter.options} // Options to display in the dropdown
                        selectedValues={cancerFilterSelected} // Preselected value to persist in dropdown
                        onSelect={onSelect} // Function will trigger on select event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Cancer Types (Top 3)"
                    />
                </Col>
                <Col className="filterInput">
                    <FloatingLabel label="Min Population (3000)" controlId="populationFilter">
                        <Form.Control type="number"></Form.Control>
                    </FloatingLabel>
                </Col>
                <Col className="filterInput">
                    <FloatingLabel label="Min Number of Cases (300)" controlId="numCasesFilter">
                        <Form.Control type="number"></Form.Control>
                    </FloatingLabel>
                </Col>
                <Col className="filterInput">
                    <FloatingLabel label="Min Crude Rate (400)" controlId="crudeRateFilter">
                        <Form.Control type="number"></Form.Control>
                    </FloatingLabel>
                </Col>
                <Col className="filterInput">
                    <FloatingLabel label="Min Age Rate (200)" controlId="ageRateFilter">
                        <Form.Control type="number"></Form.Control>
                    </FloatingLabel>
                </Col>
                <Col className="filterInput" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label style={{ marginRight: "2%" }}>Population</label>
                    <SortButton id="populationButton" eventClick={() => {resetOtherButtons(SORT_BUTTONS.POPULATION)}}/>
                </Col>
                <Col className="filterInput" style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <label style={{ marginRight: "2%" }}>Annual Cases</label>
                    <SortButton id="annualCasesButton" eventClick={() => {resetOtherButtons(SORT_BUTTONS.ANNUAL)}}/>
                </Col>
                <Col className="filterInput" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label style={{ marginRight: "2%" }}>Crude Rate</label>
                    <SortButton id="crudeRateButton" eventClick={() => {resetOtherButtons(SORT_BUTTONS.CRUDE_RATE)}}/>
                </Col>
                <Col className="filterInput" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <label style={{ marginRight: "2%" }}>Age Rate</label>
                    <SortButton id="ageRateButton" eventClick={() => {resetOtherButtons(SORT_BUTTONS.AGE_RATE)}}/>
                </Col>
            </Row>
            <p className="text-center fs-6">
                Showing {currentCards.length} of {countyData.length} Results
            </p>
            <Row
                xs={1}
                sm={1}
                md={2}
                lg={3}
                xl={3}
                className="g-2 justify-content-center"
                style={{ width: "80%", margin: "auto" }}
            >
                {currentCards.map((type) => {
                    console.log(currentCards);
                    return (
                        <Col key={type.name}>
                            <CountiesCard county={type} query={searchQuery}/>
                        </Col>
                    );
                })}
            </Row>
            <div className="text-center mt-3">
                <Button variant="primary" disabled={currentPage === 1} onClick={handleFirstPage}>
                    First
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === 1} onClick={handlePreviousPage}>
                    {"<"}
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === totalPages} onClick={handleNextPage}>
                    {">"}
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === totalPages} onClick={handleLastPage}>
                    Last
                </Button>{" "}
            </div>
            <p className="text-center fs-6">
                Page {currentPage} of {totalPages}
            </p>
        </div>
    );
}
export default Counties;