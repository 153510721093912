/* eslint-disable react/prop-types */

import React from "react";
import Card from "react-bootstrap/Card";
import "./backgroundCard.css";

const BackgroundCard = ({ title, items }) => {
    const hasItems = items.length > 0 || false;
    return (
        <Card className="bgCardWrapper">
            <Card.Title className="title">{title}</Card.Title>
            {hasItems
                ? items.map((item) => {
                      return <p key={item}>{item}</p>;
                  })
                : "None"}
        </Card>
    );
};

export default BackgroundCard;
