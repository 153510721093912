import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import "../styles/oncologist.css";
import BackgroundCard from "../components/oncologists/BackgroundCard";
import ReviewCard from "../components/oncologists/ReviewCard";
import useImage from "../utils/useImage";
import TypeCard from "../components/types/TypeCard";
// Replace with HTTPs
import axios from "axios";
import CountyCard from "../components/counties/CountyCard";
import requestPrefix from "../utils/requestPrefix";

const Oncologist = () => {
    let params = useParams();
    const id = params.oncologistId;

    const [person, setPerson] = useState(null);
    const { image } = useImage(`oncologists/${id}.png`);
    const [cancerData, setCancerData] = useState(null);
    const [countyData, setCountyData] = useState(null);

    function renderTypes() {
        return cancerData.map((type, i) => {
            return (
                <Col key={"type" + i}>
                    <TypeCard type={type} rate={0} />
                </Col>
            );
        });
    }

    function renderCounties() {
        return countyData.map((county, i) => {
            return (
                <Col key={"county" + i}>
                    <CountyCard county={county} cancerSearchName={null} />
                </Col>
            );
        });
    }

    function nameFormat(name) {
        let arr = name.replaceAll("_", " ").split(" ");
        arr = arr.map((str) => {
            return str[0].toUpperCase() + str.substring(1);
        });
        return arr.join(" ");
    }

    useEffect(() => {
        const fetchOncologistData = async () => {
            if (person) {
                return;
            }
            try {
                const response = await axios.get(`${requestPrefix}/oncologist/${id}`);
                setPerson(response.data);
                fetchCancerData(response.data);
                fetchCountyData(response.data);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        const fetchCancerData = async (oData) => {
            if (cancerData) {
                return;
            }
            try {
                let cancerNames = oData.relatedTypes;
                let outData = [];
                for (let name of cancerNames) {
                    const response = await axios.get(`${requestPrefix}/cancer_types/${name}`);
                    outData.push(response.data);
                }
                setCancerData(outData);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        const fetchCountyData = async (oData) => {
            if (countyData) {
                return;
            }
            try {
                let countyNames = oData.relatedCounties;
                let outData = [];
                for (let name of countyNames) {
                    const response = await axios.get(`${requestPrefix}/counties/${name}`);
                    outData.push(response.data);
                }
                setCountyData(outData);
            } catch (error) {
                console.error("Error fetching county data:", error);
            }
        };
        fetchOncologistData();
    }, [id]);

    if (person == null || cancerData == null || countyData == null) {
        return <>Still Loading...</>;
    }

    return (
        <div className="oncologistWrapper">
            <Row className="heroWrapper p-5 justify-content-center" xs={1} sm={1} md={2}>
                <Col style={{ width: "100%" }}>
                    <div className="nameWrapper">
                        <p className="fs-1 fw-bold header">{person.name}</p>
                        <p className="fs-3">
                            {person.rating === "None" ? "No Ratings" : person.rating + "/5★"}
                        </p>
                        <img src={image} className="oncologistImage sideBar" />
                    </div>
                    <p className="fs-2 fw-bold header">Conditions</p>
                    <Row className="conditionsWrapper m-2" sm={1} md={2} lg={3}>
                        {person.conditions.map((obj, i) => {
                            return (
                                <Col key={"cond" + i} className="mb-2">
                                    <Card>
                                        <Card.Body>
                                            <Card.Title className="conditionTitle">
                                                {nameFormat(obj.wikiTitle)}
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                    <section>
                        <p className="fs-2 fw-bold header">Training & Education</p>
                        <Row className="backgroundWrapper" sm={1} md={2}>
                            <Col className="mb-2">
                                <BackgroundCard title={"Degree"} items={person.background.degree} />
                            </Col>

                            <Col className="mb-2">
                                <BackgroundCard title={"Residency"} items={person.background.residency} />
                            </Col>

                            <Col className="mb-2">
                                <BackgroundCard title={"Fellowships"} items={person.background.fellowship} />
                            </Col>

                            <Col className="mb-2">
                                <BackgroundCard
                                    title={"Certificates"}
                                    items={person.background.certificates}
                                />
                            </Col>
                        </Row>
                    </section>
                    <section className="reviewWrapper">
                        <Row xs={1} lg={3}>
                            <Col className="reviewWrapperOncologist xs={1} lg={2}">
                                <div>
                                    <p className="fs-2 fw-bold header">Reviews</p>
                                    <p className="fs-4">
                                        {person.rating === "None"
                                            ? "No Ratings"
                                            : `${person.rating} out of 5`}
                                    </p>
                                </div>
                                <div className="reviewCardsWrapper">
                                    {person.reviews.map((review, i) => {
                                        return <ReviewCard key={"review" + i} text={review} />;
                                    })}
                                </div>
                            </Col>
                            <Col className="locationWrapperOncologist">
                                <p className="fs-2 fw-bold header">Location</p>
                                <Row className="ml-auto mr-auto" sm={1} md={2}>
                                    <Col>
                                        <iframe
                                            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_KEY}&q=${person.location.address}`}
                                            className="locationImg"
                                        ></iframe>
                                        {/* <img src={tempMap} className="oncologistLocation" /> */}
                                    </Col>
                                    <Col>
                                        <p className="fs-2">{person.location.name}</p>
                                        <p>{person.location.address}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </section>
                </Col>
            </Row>
            <Row className="text-center mt-4" xs={1}>
                <Row>
                    <h3 className="fw-bold header text-center">Relevant Cancer</h3>
                    <Row xs={1} md={2} lg={4} style={{ justifyContent: "center" }}>
                        {renderTypes()}
                    </Row>
                </Row>
                <Row>
                    <h3 className="fw-bold header text-center">Relevant Counties</h3>
                    <Row xs={1} md={2} lg={4} style={{ justifyContent: "center" }}>
                        {renderCounties()}
                    </Row>
                </Row>
            </Row>
        </div>
    );
};

export default Oncologist;
