import React from "react";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import PropTypes from "prop-types";
import useImage from "../../utils/useImage";

/**
 * Creates a card for a member.
 * Object passed in must have the filled in attributes listed below.
 * commits, issues, and unitTests in particular should have default values of 0.
 * @param {Object} param
 * @returns 
 */
function Member({ name, imageSrc, commits, issues, email, bio, role, unitTests}) {
    const { image } = useImage(imageSrc);
    return (
        <Card className="mb-4 border-secondary memberCard" data-testid="member">
            <Card.Img className="mb-2 border aboutImage" variant="top" src={image} />
            <Card.Body>
                <Card.Title data-testid="name">{name}</Card.Title>
                <Card.Subtitle data-testid="role">{role}</Card.Subtitle>
                <Card.Text data-testid="bio">{bio}</Card.Text>
                <Card.Link data-testid="email">{email}</Card.Link>
            </Card.Body>
            <ListGroup>
                <ListGroupItem data-testid="commits">{[`Commits: ${commits}`]}</ListGroupItem>
                <ListGroupItem data-testid="issues">{[`Issues Closed: ${issues}`]}</ListGroupItem>
                <ListGroupItem>{[`Unit Tests: ${unitTests}`]}</ListGroupItem>
            </ListGroup>
        </Card>
    );
}

Member.propTypes = {
    name: PropTypes.string,
    imageSrc: PropTypes.string,
    commits: PropTypes.number,
    issues: PropTypes.number,
    email: PropTypes.string,
    bio: PropTypes.string,
    role: PropTypes.string,
    unitTests: PropTypes.number
};

export default Member;
