const conditionsFilter = {
    "options": [
        {
            "name": "Thyroid & parathyroid cancers",
            "id": 0
        },
        {
            "name": "Adrenal cancer",
            "id": 1
        },
        {
            "name": "Neuroendocrine cancer",
            "id": 2
        },
        {
            "name": "Oral cancer",
            "id": 3
        },
        {
            "name": "Breast cancer",
            "id": 4
        },
        {
            "name": "Lung cancer",
            "id": 5
        },
        {
            "name": "Sarcoma",
            "id": 6
        },
        {
            "name": "Melanoma",
            "id": 7
        },
        {
            "name": "Skin cancer (nonmelanoma)",
            "id": 8
        },
        {
            "name": "Appendix cancer",
            "id": 9
        },
        {
            "name": "Gallbladder cancer",
            "id": 10
        },
        {
            "name": "Liver cancer",
            "id": 11
        },
        {
            "name": "Pancreas cancer",
            "id": 12
        },
        {
            "name": "Stomach cancer",
            "id": 13
        },
        {
            "name": "Cervical cancer",
            "id": 14
        },
        {
            "name": "Ovarian cancer",
            "id": 15
        },
        {
            "name": "Vulvar cancer",
            "id": 16
        },
        {
            "name": "Endometrial (Uterine) cancer",
            "id": 17
        },
        {
            "name": "Kidney cancer",
            "id": 18
        },
        {
            "name": "Bladder cancer",
            "id": 19
        },
        {
            "name": "Prostate cancer",
            "id": 20
        },
        {
            "name": "Colon & rectal cancer",
            "id": 21
        },
        {
            "name": "Anal cancer",
            "id": 22
        },
        {
            "name": "Testicular cancer",
            "id": 23
        },
        {
            "name": "Penile cancer",
            "id": 24
        },
        {
            "name": "Bone cancer",
            "id": 25
        },
        {
            "name": "Myeloma",
            "id": 26
        },
        {
            "name": "Lymphoma",
            "id": 27
        },
        {
            "name": "Leukemia",
            "id": 28
        },
        {
            "name": "Esophageal cancer",
            "id": 29
        },
        {
            "name": "Myelodysplastic syndrome",
            "id": 30
        },
        {
            "name": "Mesothelioma",
            "id": 31
        },
        {
            "name": "Ear cancer",
            "id": 32
        },
        {
            "name": "Nasal & sinus cancer",
            "id": 33
        },
        {
            "name": "Salivary gland tumors",
            "id": 34
        },
        {
            "name": "Tongue cancer",
            "id": 35
        },
        {
            "name": "Voice box cancer",
            "id": 36
        },
        {
            "name": "Brain cancer",
            "id": 37
        }
    ]
};

const zipCodeFilter = {
    "options": [
        {
            "name": "40536",
            "id": 0
        },
        {
            "name": "40475",
            "id": 1
        },
        {
            "name": "40508",
            "id": 2
        },
        {
            "name": "N/A",
            "id": 3
        },
        {
            "name": "40504",
            "id": 4
        },
        {
            "name": "40502",
            "id": 5
        }
    ]
};

const clinicFilter = {
    "options": [
        {
            "name": "Head, Neck & Respiratory Clinic",
            "id": 0
        },
        {
            "name": "Markey Cancer Center at Lexington Clinic Richmond",
            "id": 1
        },
        {
            "name": "Multidisciplinary Clinic",
            "id": 2
        },
        {
            "name": "Radiology–Chandler",
            "id": 3
        },
        {
            "name": "Danceblue Pediatric Hematology & Oncology",
            "id": 4
        },
        {
            "name": "Gynecologic Oncology Clinic",
            "id": 5
        },
        {
            "name": "Kentucky Clinic",
            "id": 6
        },
        {
            "name": "Pulmonary, Critical Care & Sleep Medicine",
            "id": 7
        },
        {
            "name": "Radiation Oncology",
            "id": 8
        },
        {
            "name": "Comprehensive Breast Care Center",
            "id": 9
        },
        {
            "name": "Pathology & Laboratory Medicine",
            "id": 10
        },
        {
            "name": "Hematology and Blood & Marrow Transplant",
            "id": 11
        },
        {
            "name": "N/A",
            "id": 12
        },
        {
            "name": "Markey Cancer Center at Lexington Clinic",
            "id": 13
        },
        {
            "name": "Radiology",
            "id": 14
        },
        {
            "name": "Surgery",
            "id": 15
        },
        {
            "name": "Pediatric Kidney Transplant",
            "id": 16
        },
        {
            "name": "Urology",
            "id": 17
        },
        {
            "name": "Child Neurology",
            "id": 18
        },
        {
            "name": "Genetic Counseling–Markey Cancer Center",
            "id": 19
        },
        {
            "name": "Good Samaritan Professional Arts Center",
            "id": 20
        },
        {
            "name": "Colorectal Surgery",
            "id": 21
        },
        {
            "name": "Brain Cancer",
            "id": 22
        },
        {
            "name": "Darley Blood and Marrow Transplantation Unit",
            "id": 23
        },
        {
            "name": "Hemophilia Treatment Center",
            "id": 24
        },
        {
            "name": "Vascular Interventional Radiology",
            "id": 25
        },
        {
            "name": "Endocrine Surgery",
            "id": 26
        },
        {
            "name": "Pavilion A",
            "id": 27
        },
        {
            "name": "UK HealthCare Aesthetics Center",
            "id": 28
        }
    ]
};

const schoolFilter = {
    "options": [
        {
            "name": "The Warren Alpert Medical School of Brown University, Providence, R.I.",
            "id": 0
        },
        {
            "name": "MD, University of Louisville, Louisville, Kentucky",
            "id": 1
        },
        {
            "name": "Vanderbilt University, School of Medicine, Nashville, Tenn.",
            "id": 2
        },
        {
            "name": "St. Joseph University, School of Medicine, Beirut, Lebanon",
            "id": 3
        },
        {
            "name": "University of Toledo, College of Medicine, Toledo, Ohio",
            "id": 4
        },
        {
            "name": "Mahatma Gandhi Missions Medical College, Maharashtra, India",
            "id": 5
        },
        {
            "name": "Master's in Healthcare Administration, University of Kentucky, Lexington, Ky.",
            "id": 6
        },
        {
            "name": "Pennsylvania State University College of Medicine, Hershey, Pennsylvania",
            "id": 7
        },
        {
            "name": "University of Kentucky, College of Medicine",
            "id": 8
        },
        {
            "name": "Johns Hopkins University, School of Medicine, Baltimore",
            "id": 9
        },
        {
            "name": "MD, University of Louisville, School of Medicine",
            "id": 10
        },
        {
            "name": "PhD, University of Kentucky, Lexington",
            "id": 11
        },
        {
            "name": "University of Illinois at Chicago",
            "id": 12
        },
        {
            "name": "University of Kentucky",
            "id": 13
        },
        {
            "name": "Medical College of Ohio, Toledo",
            "id": 14
        },
        {
            "name": "University of Florida, College of Medicine, Gainesville",
            "id": 15
        },
        {
            "name": "Wright State University, Boonshoft School of Medicine, Dayton, Ohio",
            "id": 16
        },
        {
            "name": "McGill University Medical School, Montreal, Canada",
            "id": 17
        },
        {
            "name": "University of Pittsburgh, School of Medicine",
            "id": 18
        },
        {
            "name": "University of Nevada, School of Medicine, Las Vegas",
            "id": 19
        },
        {
            "name": "MSN, Eastern Kentucky University, Richmond, Ky.",
            "id": 20
        },
        {
            "name": "University of Kentucky, College of Nursing, Lexington, Ky.",
            "id": 21
        },
        {
            "name": "University of Kentucky, College of Medicine, Lexington",
            "id": 22
        },
        {
            "name": "University of Toledo, Ohio",
            "id": 23
        },
        {
            "name": "MSN, Northern Kentucky University, Highland Heights, Ky.",
            "id": 24
        },
        {
            "name": "Doctor of Medicine, Vanderbilt University School of Medicine, Nashville",
            "id": 25
        },
        {
            "name": "Master of Science in Clinical Outcomes Research, University of Minnesota School of Public Health, Minneapolis",
            "id": 26
        },
        {
            "name": "Clinical Psychology, University of Kentucky",
            "id": 27
        },
        {
            "name": "University of Kentucky College of Pharmacy",
            "id": 28
        },
        {
            "name": "MD, University of Mississippi School of Medicine, Jackson, Mississippi",
            "id": 29
        },
        {
            "name": "MSN, Eastern Kentucky University, Richmond",
            "id": 30
        },
        {
            "name": "Columbia University College of Physicians and Surgeons, New York",
            "id": 31
        },
        {
            "name": "University of Louisville School of Medicine",
            "id": 32
        },
        {
            "name": "DNP, University of Kentucky, Lexington, Ky.",
            "id": 33
        },
        {
            "name": "MSN, Lincoln Memorial University, Caylor School of Nursing, Harrogate, Tenn.",
            "id": 34
        },
        {
            "name": "BSN, Eastern Kentucky University, Richmond, Ky.",
            "id": 35
        },
        {
            "name": "ADN, Eastern Kentucky University, Richmond, Ky.",
            "id": 36
        },
        {
            "name": "BSW, University of the Cumberlands, Williamsburg, Ky.",
            "id": 37
        },
        {
            "name": "Indiana Wesleyan University, Marion",
            "id": 38
        },
        {
            "name": "MSN, University of Cincinnati, Cincinnati, Ohio",
            "id": 39
        },
        {
            "name": "MSPAS, University of Kentucky",
            "id": 40
        },
        {
            "name": "BS, University of Kentucky",
            "id": 41
        },
        {
            "name": "MD, University of Miami",
            "id": 42
        },
        {
            "name": "PhD, Microbiology and Immunology, University of Miami",
            "id": 43
        },
        {
            "name": "The University of the West Indies, Mona, Jamaica",
            "id": 44
        },
        {
            "name": "University of Kentucky College of Medicine",
            "id": 45
        },
        {
            "name": "University of North Carolina at Chapel Hill, School of Medicine",
            "id": 46
        },
        {
            "name": "MSN, Walden University, Minneapolis, Minn.",
            "id": 47
        },
        {
            "name": "Southern Illinois University, School of Medicine, Springfield, Ill.",
            "id": 48
        },
        {
            "name": "MS, Biomedical Engineering, University of Wisconsin, Madison, Wis.",
            "id": 49
        },
        {
            "name": "BS, Biomedical Engineering, summa cum laude, University of Alabama at Birmingham 2011",
            "id": 50
        },
        {
            "name": "MD, University of Alabama School of Medicine (UASOM), Birmingham, Ala. 2015",
            "id": 51
        },
        {
            "name": "Suez Canal University, Ismailia, Egypt",
            "id": 52
        },
        {
            "name": "Albany Medical College of Union University, Albany, N.Y.",
            "id": 53
        },
        {
            "name": "University of Tennessee Health Science Center, Memphis",
            "id": 54
        },
        {
            "name": "Master of Science in Nursing, Northern Kentucky University, Highland Heights",
            "id": 55
        },
        {
            "name": "Harvard Medical School, Cambridge, Mass.",
            "id": 56
        },
        {
            "name": "Creighton University, School of Medicine, Omaha, Neb.",
            "id": 57
        },
        {
            "name": "American University of Beirut, Lebanon",
            "id": 58
        },
        {
            "name": "Jefferson Medical College at Thomas Jefferson University, Philadelphia",
            "id": 59
        },
        {
            "name": "University of Kentucky, College of Medicine, Lexington, Ky.",
            "id": 60
        },
        {
            "name": "Mysore Medical College, Mysore, Karnataka, India",
            "id": 61
        },
        {
            "name": "Central University of Venezuela, Razetti School of Medicine, Caracas, Venezuela",
            "id": 62
        },
        {
            "name": "University of Cincinnati, College of Medicine",
            "id": 63
        },
        {
            "name": "Yerevan State Medical University, Yerevan, Armenia",
            "id": 64
        },
        {
            "name": "Damascus University Faculty of Medicine, Damascus, Syria",
            "id": 65
        },
        {
            "name": "University of Kentucky, College of Allied Health Professionals",
            "id": 66
        },
        {
            "name": "Mount Sinai School of Medicine, New York, N.Y.",
            "id": 67
        },
        {
            "name": "Inner Mongolia Medical University, Hohhot",
            "id": 68
        },
        {
            "name": "MD, University of Kentucky, Lexington, Kentucky",
            "id": 69
        },
        {
            "name": "University of Kentucky, College of Pharmacy, Lexington, Ky.",
            "id": 70
        },
        {
            "name": "Master of Science in Nursing, Eastern Kentucky University",
            "id": 71
        },
        {
            "name": "Northern Kentucky University, Highland Heights",
            "id": 72
        },
        {
            "name": "MSN, Northern Kentucky University, Highland Heights",
            "id": 73
        },
        {
            "name": "University of Alabama, School of Medicine, Birmingham",
            "id": 74
        },
        {
            "name": "East Tennessee State University, Quillen College of Medicine, Johnson City",
            "id": 75
        },
        {
            "name": "MS, Walden University, Minneapolis, Minn.",
            "id": 76
        },
        {
            "name": "Azerbaijan Medical University, Baku, Azerbaijan",
            "id": 77
        },
        {
            "name": "PhD, University of Toronto, Canada",
            "id": 78
        },
        {
            "name": "Stanley Medical College, Chennai, Tamil Nadu, India",
            "id": 79
        },
        {
            "name": "MD, University of Louisville, Louisville, KY",
            "id": 80
        },
        {
            "name": "BA/MD Boston University School of Medicine",
            "id": 81
        },
        {
            "name": "Loyola University of Chicago, Stritch School of Medicine, Maywood, Ill.",
            "id": 82
        },
        {
            "name": "University of Kentucky College of Pharmacy, Lexington, Ky.",
            "id": 83
        },
        {
            "name": "Stanford University School of Medicine, Stanford, Calif.",
            "id": 84
        },
        {
            "name": "University of Kentucky, College of Pharmacy",
            "id": 85
        },
        {
            "name": "Kyung Hee Medical School, Seoul, Korea",
            "id": 86
        },
        {
            "name": "Texas Tech University Health Sciences Center, El Paso",
            "id": 87
        },
        {
            "name": "MD, Case Western Reserve University, Cleveland, Ohio",
            "id": 88
        },
        {
            "name": "St. Louis University, School of Medicine",
            "id": 89
        },
        {
            "name": "University of Kentucky, College of Nursing",
            "id": 90
        },
        {
            "name": "MS, Northwestern University, Feinburg School of Medicine",
            "id": 91
        },
        {
            "name": "Adult/Gerontology Acute Care Nurse Practitioner, University of Kentucky, College of Nursing, Lexington, Ky.",
            "id": 92
        },
        {
            "name": "University of Kentucky, Lexington",
            "id": 93
        },
        {
            "name": "Chittagong Medical College, Bangladesh",
            "id": 94
        },
        {
            "name": "University of Calgary, Alberta, Canada",
            "id": 95
        },
        {
            "name": "BSN, Univeristy of Kentucky",
            "id": 96
        },
        {
            "name": "BA in Biology, Transylvania University, Lexington",
            "id": 97
        },
        {
            "name": "University of Louisville, School of Medicine, Louisville, Ky.",
            "id": 98
        },
        {
            "name": "University of Chicago Pritzker School of Medicine",
            "id": 99
        },
        {
            "name": "Lake Erie College of Osteopathic Medicine, Erie, Pa.",
            "id": 100
        },
        {
            "name": "Duke University, Durham, N.C.",
            "id": 101
        },
        {
            "name": "University of Louisville, School of Medicine",
            "id": 102
        },
        {
            "name": "University of Wisconsin, School of Med. and Public Health, Madison, WI",
            "id": 103
        },
        {
            "name": "MBBch, Ain Shams University, Cairo",
            "id": 104
        },
        {
            "name": "Diploma in Nursing, Good Samaritan School of Nursing, Cincinnati, Ohio",
            "id": 105
        },
        {
            "name": "Bachelor of Science in Nursing, University of Kentucky, Lexington",
            "id": 106
        },
        {
            "name": "Master of Science in Nursing, University of Alabama, Birmingham",
            "id": 107
        },
        {
            "name": "Friedrich-Alexander Universität/Universität Erlangen-Nurnberg, Erlangen, Germany",
            "id": 108
        },
        {
            "name": "Dr.med. habil. (Faculty appointment) Ludwig-Maximilian-Universität Munich, Germany",
            "id": 109
        },
        {
            "name": "MSN, University of Kentucky",
            "id": 110
        },
        {
            "name": "DNP, Eastern Kentucky University",
            "id": 111
        },
        {
            "name": "University of Medicine, Mandalay, Myanmar (Burma)",
            "id": 112
        },
        {
            "name": "Jawaharla Institute of Post Graduate Medical Education and Research (JIPMER), Pondicherry, India",
            "id": 113
        },
        {
            "name": "Konaseema Institute of Medical Sciences, Amalapuram, Andhra Pradesh, India",
            "id": 114
        },
        {
            "name": "Indiana University, School of Medicine, Indianapolis",
            "id": 115
        },
        {
            "name": "Temple University, School of Medicine, Philadelphia",
            "id": 116
        },
        {
            "name": "Pharmacy Oncology, University of Kentucky College of Pharmacy, Lexington, Ky.",
            "id": 117
        },
        {
            "name": "Wake Forest University, School of Medicine, Winston-Salem, N.C.",
            "id": 118
        },
        {
            "name": "Bachelor of Science, Biology, University of Michigan",
            "id": 119
        },
        {
            "name": "Doctorate of Medicine, Wright State University School of Medicine, Dayton, Ohio",
            "id": 120
        },
        {
            "name": "UMDNJ, Rutgers Medical School, Piscataway, N.J.",
            "id": 121
        },
        {
            "name": "Master’s of Physician Assistant Studies from University of the Cumberlands",
            "id": 122
        },
        {
            "name": "Bachelor’s of Science from University of the Cumberlands",
            "id": 123
        },
        {
            "name": "Walden University",
            "id": 124
        },
        {
            "name": "Acute Care Nursing, University of Kentucky",
            "id": 125
        },
        {
            "name": "MS, University of Texas Health Science Center, Houston",
            "id": 126
        },
        {
            "name": "Albany Medical College, Albany, N.Y.",
            "id": 127
        },
        {
            "name": "Frontier Nursing University, Hyden, Ky.",
            "id": 128
        },
        {
            "name": "The University of Toledo, College of Medicine, Toledo, Ohio",
            "id": 129
        },
        {
            "name": "Boston University",
            "id": 130
        },
        {
            "name": "Hashemite University Faculty of Medicine, Zarqa, Jordan",
            "id": 131
        },
        {
            "name": "Carol Davila University of Medicine and Pharmacy, Bucharest, Romania",
            "id": 132
        },
        {
            "name": "Universidad del Pais Vasco, Bilbao, Spain",
            "id": 133
        },
        {
            "name": "University of the West Indies, Faculty of Medical Science, Port of Spain",
            "id": 134
        },
        {
            "name": "Rosalind Franklin University of Medicine and Science, North Chicago, Ill.",
            "id": 135
        },
        {
            "name": "Emory University, School of Medicine, Atlanta",
            "id": 136
        },
        {
            "name": "MSN, University of Kentucky, College of Nursing, Lexington, KY",
            "id": 137
        },
        {
            "name": "McGill University, College of Medicine, Montreal, Quebec, Canada",
            "id": 138
        },
        {
            "name": "University of Kentucky College of Allied Health Professionals",
            "id": 139
        },
        {
            "name": "MD, University of Kentucky, College of Medicine, Lexington",
            "id": 140
        },
        {
            "name": "PhD, University of Iowa, College of Medicine, Iowa City",
            "id": 141
        },
        {
            "name": "MSN, University of Alabama Birmingham, School of Nursing, Birmingham, Ala.",
            "id": 142
        },
        {
            "name": "Duke University, School of Medicine, Durham, N.C.",
            "id": 143
        },
        {
            "name": "Drexel University, College of Medicine (formerly Hahnemann Medical College), Philadelphia",
            "id": 144
        },
        {
            "name": "Duquesne University, Pittsburgh, Pa.",
            "id": 145
        },
        {
            "name": "University of Medicine and Dentistry of New Jersey, New Brunswick, N.J.",
            "id": 146
        },
        {
            "name": "University of Pennsylvania School of Medicine, Philadelphia",
            "id": 147
        },
        {
            "name": "University of Texas Southwestern Medical Center, Dallas",
            "id": 148
        },
        {
            "name": "College of Human Medicine, Wuhan University, China",
            "id": 149
        },
        {
            "name": "DNP, Murray State University, Murray, Ky.",
            "id": 150
        },
        {
            "name": "MD, University of Connecticut, Farmington, CT",
            "id": 151
        },
        {
            "name": "Weill Cornell Medical College, New York",
            "id": 152
        },
        {
            "name": "Hacettepe University, Ankara, Turkey",
            "id": 153
        },
        {
            "name": "BSN, University of Kentucky",
            "id": 154
        },
        {
            "name": "APRN, Eastern Kentucky University, Richmond",
            "id": 155
        },
        {
            "name": "DMD, University of Pennsylvania, School of Dental Medicine",
            "id": 156
        },
        {
            "name": "MD, Weill Cornell Medical College, New York",
            "id": 157
        },
        {
            "name": "Bachelors of Science in Nursing",
            "id": 158
        },
        {
            "name": "Bachelors of Business Adminstration",
            "id": 159
        },
        {
            "name": "MSN, Yale University - School of Nursing, West Haven, Conn.",
            "id": 160
        },
        {
            "name": "Georgetown University School of Medicine, Washington, District of Columbia",
            "id": 161
        }
    ]
};

export { conditionsFilter, zipCodeFilter, clinicFilter, schoolFilter };