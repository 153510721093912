import CountiesCard from "../components/CountiesCard";
import OncologistsCard from "../components/OncologistsCard";
import TypesCard from "../components/TypesCard";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Button, Form, Tab, Tabs } from "react-bootstrap"; // Import Row and Col from react-bootstrap
import requestPrefix from "../utils/requestPrefix";

function Search() {
    let params = useParams();
    const query = params.query || "";
    // Define state variables
    const [searchQuery, setSearchQuery] = useState(query);
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to make the fetch request
    async function fetchData() {
        try {
            setLoading(true);
            const response = await axios.post(`${requestPrefix}/searchbar`, {
                searchQuery: searchQuery
            });
            if (response.status === 200) {
                console.log(response.data);
                setSearchResults(response.data);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    }

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        fetchData(); // Call fetchData function when the form is submitted
    };

    useEffect(() => {
        if (query.length > 0) {
            fetchData();
        }
    }, []);

    return (
        <div>
            <h1 className="text-center">Search</h1>

            <Row
                className="justify-content-center align-items-center"
                style={{ width: "75vw", margin: "auto" }}
            >
                <Col>
                    <form onSubmit={handleSubmit}>
                        <Form.Control
                            type="text"
                            placeholder="Floyd..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{ width: "40vw", margin: "auto", display: "inline" }}
                        />
                        <Button type="submit" style={{ marginLeft: "1vw" }}>
                            Search
                        </Button>
                    </form>
                </Col>
            </Row>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <Container>
                    <Tabs defaultActiveKey="cancer" className="mb-3">
                        <Tab eventKey="cancer" title="Cancer">
                            <Col style={{ marginBottom: "2%" }}>
                                {searchResults.filter((result) => result.type === "cancer").length > 0 ? (
                                    <Row
                                        xs={1}
                                        sm={1}
                                        md={2}
                                        lg={3}
                                        xl={3}
                                        className="g-2 justify-content-center"
                                        style={{ width: "100%" }}
                                    >
                                        {searchResults.map(
                                            (result, i) =>
                                                result.type === "cancer" && (
                                                    <Col key={i}>
                                                        <TypesCard type={result.data} query={searchQuery} />
                                                    </Col>
                                                )
                                        )}
                                    </Row>
                                ) : (
                                    <p>No results found</p>
                                )}
                            </Col>
                        </Tab>
                        <Tab eventKey="oncologist" title="Oncologists">
                            <Col style={{ marginBottom: "2%" }}>
                                {searchResults.filter((result) => result.type === "oncologist").length > 0 ? (
                                    <Row
                                        xs={1}
                                        sm={1}
                                        md={2}
                                        lg={3}
                                        xl={3}
                                        className="g-2 justify-content-center"
                                        style={{ width: "100%" }}
                                    >
                                        {searchResults.map(
                                            (result, i) =>
                                                result.type === "oncologist" && (
                                                    <Col key={i}>
                                                        <OncologistsCard
                                                            oncologist={result.data}
                                                            query={searchQuery}
                                                        />
                                                    </Col>
                                                )
                                        )}
                                    </Row>
                                ) : (
                                    <p>No results found</p>
                                )}
                            </Col>
                        </Tab>
                        <Tab eventKey="county" title="Counties">
                            <Col style={{ marginBottom: "2%" }}>
                                {searchResults.filter((result) => result.type === "county").length > 0 ? (
                                    <Row
                                        xs={1}
                                        sm={1}
                                        md={2}
                                        lg={3}
                                        xl={3}
                                        className="g-2 justify-content-center"
                                        style={{ width: "100%" }}
                                    >
                                        {searchResults.map(
                                            (result, i) =>
                                                result.type === "county" && (
                                                    <Col key={i}>
                                                        <CountiesCard
                                                            county={result.data}
                                                            query={searchQuery}
                                                        />
                                                    </Col>
                                                )
                                        )}
                                    </Row>
                                ) : (
                                    <p>No results found</p>
                                )}
                            </Col>
                        </Tab>
                    </Tabs>
                </Container>
            )}
        </div>
    );
}

export default Search;
