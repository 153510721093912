// export function highlightText(content, query) {
//     // Skip processing if content is not a string or is empty
//     if (typeof content !== "string" || !content.trim()) {
//         return content;
//     }

//     const words = query.trim().split(/\s+/);
//     const regex = new RegExp(`\\b(${words.join("|")})\\b`, "gi");

//     // Replace matched words with <mark> tag with yellow background
//     return content.replace(regex, '<mark style="background-color: yellow;">$1</mark>');
// }

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import reactStringReplace from "react-string-replace";

const HighlightText = ({ content, query }) => {
    if(content == null || content == undefined || query == undefined) {
        return content;
    }
    content = content.toString();
    if (typeof content !== "string" || !content.trim()) {
        return content;
    }
    if (query == "") {
        return <span>{content}</span>;
    }
    const words = query.trim().split(/\s+/);
    const regex = new RegExp(`(${words.join("|")})`, "gi");

    // Replace matched words with <mark> tag with yellow background
    let highlightedContent = reactStringReplace(content, regex, (match, i) => (
        <mark style={{ backgroundColor: "yellow" }}>{match}</mark>
    ));

    return <span>{highlightedContent}</span>;
};

export default HighlightText;
