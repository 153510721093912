import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Types from "./pages/Types";
import Type from "./pages/Type";
import Oncologists from "./pages/Oncologists";
import Counties from "./pages/Counties";
import NoPage from "./pages/NoPage";
import Oncologist from './pages/Oncologist';
import Search from './pages/Search';
import County from './pages/County';
import Visualizations from './pages/Visualizations';
import ProviderVisualizations from './pages/ProviderVisualizations'

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="types" element={<Types />} />
                    <Route path="type/:typeName" element={<Type />} />
                    <Route path="oncologists" element={<Oncologists />} />
                    <Route path="/oncologists/:oncologistId" element={<Oncologist />}/>
                    <Route path="counties" element={<Counties />} />
                    <Route path="/county/:name" element={<County />} />
                    <Route path="search/:query" element={<Search />}/>
                    <Route path="visualizations" element={<Visualizations />}/>
                    <Route path="provider_visualizations" element={<ProviderVisualizations />}/>
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
