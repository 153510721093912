import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import useImage from "../../utils/useImage";

/**
 * Creates a Card for a Technology we used.
 * imageSrc should be an image folder relative path for the image.
 * e.g: tech/bootstrap.png -> images/tech/bootstrap.png
 * @param {Object} param 
 * @returns 
 */
function TechStack({ name, imageSrc, description }) {
    const { image } = useImage(imageSrc);
    return (
        <Card className="mb-4 border-secondary techStackCard">
            <Card.Img className="mb-2 border" variant="top" src={image} style={{width: '100%'}}/>
            <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>{description}</Card.Text>
            </Card.Body>
        </Card>
    );
}

TechStack.propTypes = {
    name: PropTypes.string,
    imageSrc: PropTypes.string,
    description: PropTypes.string
};

export default TechStack;
