import React, { useState, useEffect } from "react";
import { Row, Col, Button, FloatingLabel, Form } from "react-bootstrap";
import TypesCard from "../components/TypesCard";
import requestPrefix from "../utils/requestPrefix";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import SortButton from "../components/SortButton";
import { classificationFilter, symptomFilter, bodyFilter, specialtyFilter } from "../utils/cancerFilter";

function Types() {
    document.title = "Types of Cancer";
    const [currentPage, setCurrentPage] = useState(1);
    const [cancerData, setCancerData] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const cardsPerPage = 9;

    let [classificationsFilterSelected, setClassificationsFilterSelected] = useState([]);
    let [symptomsFilterSelected, setSymptomsFilterSelected] = useState([]);
    let [bodyFilterSelected, setBodyFilterSelected] = useState([]);
    let [specialtyFilterSelected, setSpecialtyFilterSelected] = useState([]);

    useEffect(() => {
        const fetchCancerData = async () => {
            if (cancerData) {
                return;
            }
            try {
                const response = await axios.get(`${requestPrefix}/cancer_types`);
                let data = response.data;
                setCancerData(data);
            } catch (error) {
                console.error("Error on fetching oncologist data:", error);
                window.location = "/404";
            }
        };
        fetchCancerData();
    });

    if (cancerData == null) {
        return <>Still Loading...</>;
    }

    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cancerData.slice(indexOfFirstCard, indexOfLastCard);
    const totalPages = Math.ceil(cancerData.length / cardsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const handleFirstPage = () => {
        setCurrentPage(1)
    };
    
    const handleLastPage = () => {
        setCurrentPage(totalPages)
    };

    // async function makeSearch() {
    //     if (!searchQuery) {
    //         // If searchQuery is empty, set oncologistData to its initial state
    //         setCurrentPage(1);
    //         setCancerData(cancerData);
    //         return;
    //     }
    //     const body = {
    //         searchQuery: searchQuery
    //     };
    //     const response = await axios.post(`${requestPrefix}/cancer_types/searchbar`, body, {
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     });
    //     if (response.status == 200){
    //         setCurrentPage(1);
    //         setCancerData(response.data);
    //     }
    // }

    async function makeFetch() {
        const classifications = classificationsFilterSelected.map((x) => x.name);
        const symptoms = symptomsFilterSelected.map((x) => x.name);
        const partsOfBody = bodyFilterSelected.map((x) => x.name);
        const specialties = specialtyFilterSelected.map((x) => x.name);

        const body = {
            searchQuery: searchQuery,
            filterParams: {
                classifications: classifications,
                symptoms: symptoms,
                body: partsOfBody,
                specialty: specialties,
                frequency: Number(document.getElementById("frequencyFilter").value)
            },
            sortParams: {
                frequency: document.getElementById("frequencyButton").getAttribute("direction")
            }
        };
        const response = await axios.post(`${requestPrefix}/cancer_types/search`, body, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        if (response.status == 200) {
            setCurrentPage(1);
            setCancerData(response.data);
        }
    }

    return (
        <div className="app">
            <h1 className="text-center">Types of Cancer</h1>
            <Row className="justify-content-center align-items-center" style={{ width: "88.25vw", margin: "auto" }}>
                <Col>
                    <FloatingLabel
                        label="Search"
                        controlId="search"
                        style={{ width: "42.25vw", margin: "auto" }}
                    >
                        <Form.Control
                            type="text"
                            placeholder="Search by name"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </FloatingLabel>
                </Col>
                <Col>
                    <Button onClick={makeFetch}>Search</Button>
                </Col>
            </Row>

            <Row
                xs={1}
                sm={2}
                md={4}
                lg={4}
                style={{ padding: "1vw", width: "90vw", margin: "auto" }}
                className="justify-content-center align-items-center"
            >
                <Col className="filterInput">
                    <Multiselect
                        options={classificationFilter.options} // Options to display in the dropdown
                        selectedValues={classificationsFilterSelected} // Preselected value to persist in dropdown
                        onSelect={(selectedList) => {
                            setClassificationsFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setClassificationsFilterSelected(selectedList);
                        }}
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Classifications"
                    />
                </Col>
                <Col className="filterInput">
                    <Multiselect
                        options={symptomFilter.options}
                        selectedValues={symptomsFilterSelected}
                        onSelect={(selectedList) => {
                            setSymptomsFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setSymptomsFilterSelected(selectedList);
                        }}
                        displayValue="name"
                        placeholder="Symptoms"
                    />
                </Col>
                <Col className="filterInput">
                    <Multiselect
                        options={bodyFilter.options}
                        selectedValues={bodyFilterSelected}
                        onSelect={(selectedList) => {
                            setBodyFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setBodyFilterSelected(selectedList);
                        }}
                        displayValue="name"
                        placeholder="Parts of Body"
                    />
                </Col>
                <Col className="filterInput">
                    <Multiselect
                        options={specialtyFilter.options}
                        selectedValues={specialtyFilterSelected}
                        onSelect={(selectedList) => {
                            setSpecialtyFilterSelected(selectedList);
                        }}
                        onRemove={(selectedList) => {
                            setSpecialtyFilterSelected(selectedList);
                        }}
                        displayValue="name"
                        placeholder="Specialties"
                    />
                </Col>
                <Col className="filterInput">
                    <FloatingLabel label="Min Frequency (300000)" controlId="frequencyFilter">
                        <Form.Control type="number"></Form.Control>
                    </FloatingLabel>
                </Col>
                <Col
                    className="filterInput"
                    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <label style={{ marginRight: "2%" }}>Frequency</label>
                    <SortButton id="frequencyButton" />
                </Col>
            </Row>
            <p className="text-center fs-6">
                Showing {currentCards.length} of {cancerData.length} Results
            </p>
            <Row
                xs={1}
                sm={1}
                md={2}
                lg={3}
                xl={3}
                className="g-2 justify-content-center"
                style={{ width: "80%", margin: "auto" }}
            >
                {currentCards.map((type) => {
                    return (
                        <Col key={type.name}>
                            <TypesCard type={type} query={searchQuery}/>
                        </Col>
                    );
                })}
            </Row>
            <div className="text-center mt-3">
                <Button variant="primary" disabled={currentPage === 1} onClick={handleFirstPage}>
                    First
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === 1} onClick={handlePreviousPage}>
                    {"<"}
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === totalPages} onClick={handleNextPage}>
                    {">"}
                </Button>{" "}
                <Button variant="primary" disabled={currentPage === totalPages} onClick={handleLastPage}>
                    Last
                </Button>{" "}
            </div>
            <p className="text-center fs-6">
                Page {currentPage} of {totalPages}
            </p>
        </div>
    );
}

export default Types;
